import React from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { OverridableComponent } from '@material-ui/core/OverridableComponent'
import type { TypographyTypeMap as MuiTypographyTypemap } from '@material-ui/core/Typography'
import MuiTypography from '@material-ui/core/Typography'
import classnames from 'classnames'
import NumberFormat from '@ui/core/NumberFormat'

const useStyles = makeStyles<Theme>((theme) => {
  return {
    root: (props: any) => ({
      fontWeight: props?.weight
    }),
    error: {
      color: theme.palette.error.main
    }
  }
})

export interface TypographyTypeMap<P = unknown, D extends React.ElementType = 'span'>
  extends MuiTypographyTypemap<P, D> {
  props: MuiTypographyTypemap<P, D>['props'] & {
    format?: string
    value?: number
    showUnits?: boolean
    showZeros?: boolean
    weight?: 'bold' | 'normal' | number
  }
}

/**
 * Extension of Material-UI's Typography component to add formatting helpers
 *
 */
const Typography: OverridableComponent<TypographyTypeMap> = (props: any) => {
  const typographyClasses = useStyles(props)
  const { format, showUnits, showZeros, ...typographyProps } = props
  const value = props.value ?? 0
  const className = classnames({
    [typographyClasses.root]: !!typographyClasses.root,
    [props.className]: !!props.className,
    [typographyClasses.error]: props.color === 'error'
  })

  return (
    <MuiTypography
      {...typographyProps}
      classes={typographyClasses}
      className={className}
      color={props.color === 'error' ? 'inherit' : props.color} // keep material-ui prop validation happy
    >
      <NumberFormat format={format} showUnits={showUnits} showZeros={showZeros} value={value}>
        {props.children}
      </NumberFormat>
    </MuiTypography>
  )
}

export default Typography
