import { Chip, Typography, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import type { ReactNode } from 'react'
import React from 'react'
import type { WithUseStyles } from '../styles'
import Skeleton from '../Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.typography.pxToRem(26),
    borderRadius: 2,
    width: '100%',
    backgroundColor: theme.palette.grey[100]
  },
  labelWrapper: {
    padding: theme.spacing(0.5, 1)
  },
  label: {
    display: 'inline',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    width: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    height: '90%',
    paddingLeft: theme.spacing(0.5),
    borderLeft: '1px solid white'
  },
  dot: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: theme.spacing(),
    backgroundColor: theme.palette.quote.status.draft,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-2px'
  }
}))

export interface FilterChipProps extends WithUseStyles<typeof useStyles> {
  label: ReactNode
  color?: string
  onDelete?: () => void
  loading?: boolean
}

export default function FilterChip({ label, color, onDelete, loading, ...props }: FilterChipProps) {
  const classes = useStyles(props)
  return loading ? (
    <Skeleton className={classes.root} />
  ) : (
    <Chip
      classes={{ root: classes.root, label: classes.labelWrapper, deleteIcon: classes.icon }}
      label={
        <Typography className={classes.label} variant="caption" align="center">
          {color && <div className={classes.dot} style={{ backgroundColor: color }} />}
          {label}
        </Typography>
      }
      onDelete={onDelete}
      deleteIcon={<CloseIcon />}
    />
  )
}
