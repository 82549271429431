import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import type { InputFieldProps } from '../InputField'
import { useClientOptions } from '../ClientOptionsProvider'
import type { InputFieldFormat } from '@ui/core/InputField'

// Helper for formatting input
const FormattedInput = (props: InputFieldProps & { format: InputFieldFormat }) => {
  const { inputRef: _inputRef, onChange, format, name, prefix, suffix, value: rawValue, ...other } = props
  const { options } = useClientOptions()

  const thousandSeparator = true
  const fixedDecimalScale = false // format === 'price'
  const [blurLoad, setBlurLoad] = useState<boolean>(false)
  const [selected, setSelected] = useState<boolean>(false)
  const emptyValueLoad =
    rawValue === '0.00' || (rawValue === 0 && !blurLoad && selected && ['price', 'rate'].includes(format))
  const value =
    ['dimension', 'hours'].includes(format) && rawValue === 0
      ? ''
      : emptyValueLoad
        ? format === 'rate'
          ? undefined
          : ''
        : rawValue
  const decimalScale = format === 'coordinate' ? 6 : format === 'taxRate' ? 3 : emptyValueLoad ? 0 : 2
  const allowNegative =
    options?.options?.features?.allowNegativeNumberInputs || ['price', 'coordinate'].includes(format)

  return (
    <NumberFormat
      {...(other as any)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
          ...event,
          target: {
            ...event.target,
            name,
            value: event.target.value.replace(/\$|,|/g, '')
          }
        })
      }}
      name={name}
      onBlur={() => {
        setSelected(false)
        setBlurLoad(true)
      }}
      onFocus={() => {
        setSelected(true)
        setBlurLoad(false)
      }}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      suffix={suffix}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      value={value as any}
      allowNegative={allowNegative}
    />
  )
}

export default FormattedInput
