import Grid from '@material-ui/core/Grid'
import type { Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Email from '@material-ui/icons/Email'
import { Field, FastField, useFormikContext, Form } from 'formik'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { getObjectLabels, getFeature, getFeatures } from '@paintscout/util/builder'
import UploadImage from '../../UploadImage'
import Button from '../../Button'
import { useClientOptions } from '../../ClientOptionsProvider'

import { FormikInputField, FormikHtmlEditor } from '../../formik'
import { NewFormSection as FormSection } from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import PhoneNumberInput, { PhoneNumberExtensionInput } from '../../PhoneNumberInput'
import SignatureCanvas from '../../SignatureCanvas'
import type { Auth0UserWithClaims } from '../../UserProvider/UserContext'
import type { UserPreferencesDocument } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import type { StyleClasses } from '@ui/core/theme'
import SettingsPage from '../../SettingsPage'
import { useGetApiKeysQuery } from '@paintscout/api'
import ApiKey from '../../ApiKey'
import Spinner from '../../Spinner'
import Typography from '../../Typography'

const useStyles = makeStyles<Theme, UserProfileProps>(
  (theme) => ({
    root: {},
    contactInfo: {
      paddingBottom: theme.typography.pxToRem(15)
    },
    changeInfoButtons: {
      maxWidth: 450
    },
    changeInfoButton: {
      display: 'flex',
      justifyContent: 'left',
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0)
      }
    },
    canvasButton: {
      justifyContent: 'left'
    },
    presentation: {},
    avatarDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3.5),
      marginLeft: theme.spacing(),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(4)
      }
    },
    defaultPadding: {
      paddingBottom: theme.spacing(1.5)
    },
    keyContainer: {
      padding: theme.spacing(2)
    }
  }),
  { name: 'UserProfileForm' }
)

export interface UserProfileProps {
  classes?: StyleClasses<typeof useStyles>
  disableSignature?: boolean
  allowEmailChange?: boolean
  showChangePassword?: boolean
  extendedSubmitting?: boolean

  onChangePassword?: () => any
}

function UserProfileForm(props: UserProfileProps) {
  const classes = useStyles(props)
  const { showChangePassword, disableSignature, allowEmailChange, extendedSubmitting } = props
  const { options } = useClientOptions()
  const { values, setFieldValue, errors } = useFormikContext<{
    user: Auth0UserWithClaims
    preferences: UserPreferencesDocument
  }>()
  const { enqueueSnackbar } = useSnackbar()
  const roles = values?.user?.app_metadata?.roles ?? []
  const canBeSales = roles.includes('sales') || roles.includes('superadmin') || roles.includes('admin')
  const isAdmin = roles.includes('superadmin') || roles.includes('admin')
  const objectLabels = getObjectLabels({ options })
  const hasPresentations = getFeature({ options, path: 'presentation.enabled' })
  const hasAboutYourEstimator = getFeature({ options, path: 'presentation.aboutYourEstimator' })
  const hasSettingsIntegrations = getFeature({ options, path: 'settingsIntegrations.enabled' })
  const { integrations } = getFeatures({ options })
  const providers = integrations && integrations.providers ? integrations.providers : {}
  const hasApiKeyFeature = providers?.paintscoutApiKeys ?? false
  const customAvatar = values?.user?.user_metadata?.customAvatar
  const {
    data: apiKeysData,
    loading,
    error
  } = useGetApiKeysQuery({
    fetchPolicy: 'cache-and-network',
    skip: isAdmin || !hasApiKeyFeature || !hasSettingsIntegrations
  })

  const handleAvatarClear = () => {
    const customAvatarValue = {
      value: '',
      cdnValue: null,
      cloudinaryId: null,
      s3PublicKey: null,
      fileType: null
    }

    setFieldValue('user.user_metadata.customAvatar', customAvatarValue)
  }
  const handleAvatarUpload = ({
    src: newSrc,
    format,
    cloudinaryPublicId,
    s3PublicKey,
    width,
    height
  }: {
    src: string
    format: string
    cloudinaryPublicId: string
    s3PublicKey: string
    width: number
    height: number
  }) => {
    const customAvatarValue = {
      value: newSrc,
      cdnValue: newSrc,
      cloudinaryId: cloudinaryPublicId,
      s3PublicKey,
      width,
      height,
      fileType: format
    }
    setFieldValue('user.user_metadata.customAvatar', customAvatarValue)
  }

  const handlePresentationUpload = (image: {
    src: string
    cloudinaryPublicId?: string
    s3PublicKey?: string
    format: string
    width: number
    height: number
    type: string
  }) => {
    setFieldValue('preferences.presentation.image' as any, image)
  }

  const handlePresentationClear = () => {
    setFieldValue('preferences.presentation.image' as any, null)
  }

  const handleSignatureChange = (event: any, data?: string) => {
    setFieldValue('preferences.signature' as any, data)
  }

  const handleSignatureUploadError = (type?: string) => {
    if (type) {
      enqueueSnackbar(`Unable to upload signature, ${type} file format is not currently supported.`, {
        variant: 'error'
      })
    } else {
      enqueueSnackbar('Unable to upload signature', { variant: 'error' })
    }
  }

  return (
    <SettingsPage title="Profile">
      <Form className={classes.root}>
        <FormSection>
          <Grid item xs={12}>
            <FormSectionTitle
              title={'Your Photo'}
              subTitle={'Upload an image to use as your profile photo.'}
              variant="h4"
            />
          </Grid>
          <Grid className={classes.avatarDiv} item xs={2}>
            {extendedSubmitting ? (
              <Spinner />
            ) : customAvatar?.value ? (
              <FastField name="user.user_metadata.customAvatar">
                {({ field }) => (
                  <UploadImage
                    avatar
                    cloudinaryPublicId={field.value?.cloudinaryId}
                    s3PublicKey={field.value?.s3PublicKey}
                    src={field.value?.value ?? field.value?.s3PublicKey ?? field.value?.cloudinaryId}
                    onUpload={handleAvatarUpload}
                    onClear={() => handleAvatarClear()}
                  />
                )}
              </FastField>
            ) : (
              <FastField name="user.picture">
                {({ field }) => (
                  <div className={classes.defaultPadding}>
                    <UploadImage
                      avatar
                      cloudinaryPublicId={field.value}
                      src={field.value}
                      onUpload={handleAvatarUpload}
                      onClear={() => handleAvatarClear()}
                      disableClear
                      showUploadButton
                    />
                  </div>
                )}
              </FastField>
            )}
          </Grid>
          <FormSectionTitle
            title={'Contact Information'}
            variant={'h4'}
            subTitle={`Shown on ${objectLabels.quote.plural.toLowerCase()} to help customers contact you.`}
          />
          <Grid container spacing={2} className={classes.contactInfo}>
            <Grid item xs={6} sm={5}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.firstName"
                label={'First Name'}
                required
                fullWidth
                helperTextState="error"
                helperText={get(errors, 'user.user_metadata.firstName')}
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <Field
                component={FormikInputField}
                name="user.user_metadata.lastName"
                label={'Last Name'}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.contactInfo}>
            <Grid item xs={6} sm={3}>
              <Field
                component={FormikInputField}
                inputComponent={PhoneNumberInput}
                inputProps={{ separator: options?.options?.phoneNumbers?.separator }}
                name="user.user_metadata.phoneNumber"
                label={'Phone Number'}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <Field
                component={FormikInputField}
                inputComponent={PhoneNumberExtensionInput}
                name="user.user_metadata.phoneExtension"
                label={'EXT.'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Field component={FormikInputField} name="user.user_metadata.title" label={'Title'} fullWidth />
            </Grid>
            <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
              <Field
                component={FormikInputField}
                name="user.email"
                disabled={!allowEmailChange}
                margin="dense"
                label={'Email'}
                icon={Email}
                fullWidth={true}
              />
            </Grid>
            {showChangePassword && (
              <Grid item={true} xs={12} sm={6}>
                <Button className={classes.changeInfoButton} icon={EditIcon} onClick={props.onChangePassword}>
                  Change Password
                </Button>
              </Grid>
            )}
          </Grid>
        </FormSection>
        {canBeSales && (
          <FormSection>
            <FormSectionTitle
              title={'Signature'}
              variant={'h4'}
              subTitle={`Place your signature here to auto-populate on accepted ${objectLabels.quote.plural.toLowerCase()}.`}
            />
            {!disableSignature ? (
              <SignatureCanvas
                newSignatureButtons
                disabled={disableSignature}
                caption={'Sign Here'}
                classes={{ captionRow: classes.canvasButton }}
                value={values.preferences?.signature ?? ''}
                onChange={handleSignatureChange}
                onUploadError={handleSignatureUploadError}
                allowUpload={true}
              />
            ) : (
              <img src={values.preferences.signature} style={{ width: '100%', maxWidth: 450, height: 150 }} />
            )}
          </FormSection>
        )}
        {hasPresentations && canBeSales && hasAboutYourEstimator && (
          <FormSection>
            <FormSectionTitle
              title={'Presentation'}
              variant={'h4'}
              subTitle={
                'More information about you, which can be added as an "About Your Estimator" section of presentations'
              }
            />
            <Grid container={true} spacing={4} className={classes.presentation}>
              <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
                <UploadImage
                  cloudinaryPublicId={values?.preferences?.presentation?.image?.cloudinaryPublicId}
                  s3PublicKey={values?.preferences?.presentation?.image?.s3PublicKey}
                  src={values?.preferences?.presentation?.image?.src}
                  onUpload={handlePresentationUpload}
                  onClear={handlePresentationClear}
                />
              </Grid>
              <Grid item={true} xs={12} sm={6} lg={6} xl={6}>
                <FastField
                  component={FormikHtmlEditor}
                  name="preferences.presentation.description"
                  margin="dense"
                  label={'Description/Bio'}
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormSection>
        )}
        {hasSettingsIntegrations && hasApiKeyFeature && apiKeysData?.getApiKeys?.length > 0 && !isAdmin && (
          <FormSection>
            <FormSectionTitle
              title={'Api Keys'}
              variant={'h4'}
              subTitle={
                'Api keys assigned to your account. These keys are used to authenticate with PaintScout services. Do not share these keys with anyone.'
              }
            />
            <Grid className={classes.keyContainer} container spacing={2} xs={12}>
              {loading && <Spinner />}
              {error && (
                <Grid item xs={12}>
                  <Typography variant="h6">Error retrieving Api Keys.</Typography>
                </Grid>
              )}
              {!loading &&
                !error &&
                apiKeysData?.getApiKeys
                  ?.reduce((prev, current, _) => {
                    // List active keys first
                    if (current.archived) {
                      return prev
                    } else {
                      prev.unshift(current)
                    }
                    return prev
                  }, [])
                  .map((apiKey) => {
                    return <ApiKey key={apiKey._id} apiKey={apiKey} onEdit={null} />
                  })}
            </Grid>
          </FormSection>
        )}
      </Form>
    </SettingsPage>
  )
}

export default UserProfileForm
