import type { AreaSubstrate, QuoteArea, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { productVolume } from './product-volume'
import { roundPriceQuoteOptions } from '../util'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { getSubstrateDimension } from './get-substrate-dimension'

export function pricePerValueSubstrate(substrate: AreaSubstrate, area: QuoteArea, quote: QuoteDocument) {
  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}
  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const rateType = substrate.rate.rate_type
  const rateValue = parseNumber(substrate.rate_value)
  const prep = parseNumber(substrate.prep)
  const coats = parseNumber(substrate.coats)
  const products = [...(substrate?.product ? [substrate.product] : []), ...(substrate?.products ?? [])]
  const quantity = rateType === 'quantity' ? parseNumber(substrate.quantity, 1) : 1
  const dimension = getSubstrateDimension({ substrate, area, quote, rateType, quantity, calcType: 'price-per-value' })

  productVolume(
    quote,
    products,
    rateType,
    parseNumber(dimension),
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  let defaultPrice = parseNumber(dimension) * rateValue * quantity + hourlyRate * prep
  let price = defaultPrice
  if (substrate.override_price) {
    if (typeof substrate.override_price_value !== 'undefined' && substrate.override_price_value !== null) {
      price = substrate.override_price_value
    } else {
      price = substrate.price
    }
  }

  defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

  return {
    ...substrate,
    hours: 0,
    price,
    default_price: defaultPrice
  }
}
