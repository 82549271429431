/**
 * @module builder
 */
import numeral from 'numeral'
import type { OptionsDocument } from 'paintscout'
import { getQuoteOptions } from '../../quote'
import { getObjectLabels } from '../get-object-labels'

export function formatCurrency(args?: {
  options?: OptionsDocument
  value: string | number
  abbreviate?: boolean
  abbreviationThreshold?: number
  abbreviationPostfix?: string
  forceCurrency?: '$' | '€' | '£' | ''
}): string {
  const { value, options, abbreviate, abbreviationThreshold = 10000, abbreviationPostfix = '.00', forceCurrency } = args

  const { separateThousands } = getQuoteOptions({ options })
  const { currency } = getObjectLabels({ options })
  const symbol = typeof forceCurrency === 'undefined' ? currency?.symbol : forceCurrency

  // replace any non-numeric characters
  const sanitizedValue = typeof value === 'string' ? value.replace(/[^0-9.]/g, '') : value

  let format: string
  if (abbreviate)
    format =
      parseFloat(sanitizedValue as string) > abbreviationThreshold
        ? `$0${abbreviationPostfix}a`
        : separateThousands
          ? `$0,0${abbreviationPostfix}`
          : `$0${abbreviationPostfix}`

  if (!abbreviate) format = separateThousands ? `$0,0${abbreviationPostfix}` : `$0${abbreviationPostfix}`

  const formattedString = numeral(sanitizedValue).format(format)

  // Numeral doesn't like directly passing in different currency symbols
  return symbol !== '$' ? formattedString.replace('$', symbol) : formattedString
}
