import type { ValueFilter } from 'json-to-lucene'

/**
 * Helper method to update a range filter
 */
export function handleRangeFilterChange({
  id,
  start,
  end,
  filters,
  onChange
}: {
  id: string
  start?: any
  end?: any
  filters: ValueFilter[]
  onChange: (filters: ValueFilter[]) => void
}) {
  const prevValue = filters.find((filter) => filter.id === id)?.value ?? []

  if (typeof start !== 'undefined' && typeof end !== 'undefined') {
    handleFilterChange(
      {
        id,
        type: 'range',
        value: [start, end]
      },
      filters,
      onChange
    )
  } else {
    if (typeof start !== 'undefined') {
      handleFilterChange(
        {
          id,
          type: 'range',
          value: [start, prevValue[1]]
        },
        filters,
        onChange
      )
    }

    if (typeof end !== 'undefined') {
      handleFilterChange(
        {
          id,
          type: 'range',
          value: [prevValue[0], end]
        },
        filters,
        onChange
      )
    }
  }
}

export function handleFilterChange(filter: ValueFilter, filters, onChange: (filters: ValueFilter[]) => void) {
  onChange([...filters.filter(({ id }) => id !== filter.id), filter])
}
