// import { makeStyles } from '@material-ui/core'
// import type { Theme } from '@material-ui/core/styles'
import type { ResultsTableProps } from '@ui/paintscout'
import { ResultsTable, TableCell, TableRow, Typography } from '@ui/paintscout'
import type { ClientMetaDocument } from 'paintscout'
import React, { useCallback } from 'react'

export interface CouponCodeTableProps
  extends Omit<ResultsTableProps, 'rows' | 'columns' | 'classes' | 'page' | 'renderRow' | 'rowsPerPage'> {
  rows: any[]
  hideEmail?: boolean
  hideRedemptions?: boolean
  onClientClick?: (rowId: string, client: Partial<ClientMetaDocument>) => any
  onClientDeleted?: () => any
}

export default function CouponCodeTable(props: CouponCodeTableProps) {
  const { rows, onClientClick, hideEmail, hideRedemptions, ...other } = props
  const renderRow = useCallback((row) => {
    // render cell as a link unless onClientClick is defined
    const cellProps = (rowId: string) => ({
      row,
      disableLink: !!onClientClick,
      style: onClientClick ? { cursor: 'pointer' } : undefined,
      onClick: onClientClick
        ? () => {
            // prevent link navigation
            // e.preventDefault()

            onClientClick(rowId, row)
          }
        : null
    })

    return (
      <TableRow key={row._id}>
        <TableCell {...cellProps('code')}>
          <Typography variant={'body1'}>{row.code}</Typography>
        </TableCell>
        {!hideEmail && (
          <TableCell {...cellProps('email')}>
            <Typography variant={'body1'}>{row.email}</Typography>
          </TableCell>
        )}
        <TableCell {...cellProps('name')}>
          <Typography variant={'body1'}>{row.name}</Typography>
        </TableCell>
        {!hideRedemptions && (
          <TableCell {...cellProps('redemptions')}>
            <Typography showZeros={!row.redemptions} variant={'body1'}>
              {row.redemptions}
            </Typography>
          </TableCell>
        )}
        <TableCell {...cellProps('status')}>
          <Typography variant={'body1'}>{row.status}</Typography>
        </TableCell>
      </TableRow>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResultsTable
      rowsPerPage={1000}
      rows={rows || []}
      noResultsMessage="No results"
      columns={
        <TableRow>
          <TableCell>Code</TableCell>
          {!hideEmail && <TableCell>Email</TableCell>}
          <TableCell>Name</TableCell>
          {!hideRedemptions && <TableCell>Redemptions</TableCell>}
          <TableCell>Status</TableCell>
          {/* <TableCell>Delete</TableCell> */}
        </TableRow>
      }
      renderRow={renderRow}
      {...other}
    />
  )
}
