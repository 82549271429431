import { useApolloClient } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core'
import type { LineItemOption } from '@paintscout/util/builder'
import {
  copyLineItemOption,
  createLineItemOption,
  deleteLineItemOption,
  deleteLineItemOptions,
  getLineItemOptions,
  getObjectLabels,
  reorderLineItemOptions
} from '@paintscout/util/builder'
import * as Sentry from '@sentry/core'
import {
  AlertDialog,
  ConfirmationDialog,
  NewFormSection as FormSection,
  Grid,
  Spinner,
  Typography,
  useClientOptions,
  useDialogs,
  useUser
} from '@ui/paintscout'
import SettingsPage from '@ui/paintscout/src/SettingsPage'
import type { ValueType } from '@ui/paintscout/src/TileList'
import { gql } from 'apollo-boost'
import FileSaver from 'file-saver'
import { useFormikContext } from 'formik'
import kebabCase from 'lodash/kebabCase'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import type { ClientMetaDocument, OptionsDocument, RatesDocument } from 'paintscout'
import React, { useState } from 'react'
import OptionsTileList from '../../OptionsTileList'
import BulkTypeAssignDialog from '../../dialogs/BulkTypeAssignDialog'
import EditLineItemOptionDialog from '../../dialogs/EditLineItemOptionDialog'

const useStyles = makeStyles((theme) => ({
  root: {},
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(5)}px ${theme.spacing()}px 0`
  },
  title: {
    padding: 0
  }
}))

export interface LineItemOptionsProps {
  onDialogConfirm: () => void
}

function LineItemOptions({ onDialogConfirm }: LineItemOptionsProps) {
  const classes = useStyles({})
  const { isAdmin } = useClientOptions()
  const { isSuperadmin, isSupport } = useUser()
  const apolloClient = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog, dismissAllDialogs } = useDialogs()
  const [quoteType, setQuoteType] = useState('all')
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
    meta?: ClientMetaDocument // Only in admin
  }>()
  const [selected, setSelected] = useState<ValueType[]>([])

  const { options, meta } = values

  const items = getLineItemOptions({ options, quoteType, inactive: true })
  const objectLabels = getObjectLabels({ options })

  return (
    <SettingsPage
      title={'Line Items'}
      callout={{
        path: 'settings-line-items',
        content: `A line item outlines a specific component of the ${objectLabels.quote.value.toLowerCase()}. It can have pricing information or can simply be used to provide a description.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/3207003-line-items'
      }}
    >
      <div className={classes.root}>
        <FormSection
          hidePadding
          selectHeader
          selectValue={quoteType}
          changeSelect={setQuoteType}
          buttonAction={handleExport}
        >
          <OptionsTileList
            title={''}
            subtitle={''}
            items={items.map((item) => ({ ...item, key: item.name }))}
            createTitle={'Add New Line Item'}
            showCreate={true}
            selected={selected}
            setSelected={setSelected}
            onDeleteBulkItems={handleDeleteBulkItems}
            onAssignBulkItems={isAdmin || isSuperadmin || isSupport ? handleAssignBulkItems : null}
            onReorder={handleReorder}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            onCreateClick={handleCreateItem}
            onCopyItem={handleCopyItem}
            noItemTitle={'No Line Items'}
            noItemHelpText={'Custom Line Items for specific descriptions or prices.'}
          />
        </FormSection>
      </div>
    </SettingsPage>
  )

  async function handleExport() {
    openDialog(AlertDialog, {
      title: `Export Line items`,
      actions: [
        {
          label: 'Cancel',
          onClick: dismissDialog,
          left: true,
          variant: 'text'
        }
      ],
      message: (
        <Grid container spacing={2} alignItems={'center'} direction={'column'}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom={true}>
              Please wait while we export your Line Items.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Spinner />
          </Grid>
        </Grid>
      )
    })

    const dateFormat = options.options.dateFormat.momentValue
    const companyName = options.options?.companyName?.value ?? ''
    const fileName = `${kebabCase(companyName as string)}-line-items-${moment(Date.now()).format(dateFormat)}.csv`

    try {
      const { data } = await apolloClient.query({
        query: gql`
          query exportLineItems($id: String) {
            exportLineItems(id: $id) {
              csv
            }
          }
        `,
        variables: {
          id: meta?._id ?? null
        },
        fetchPolicy: 'no-cache'
      })
      if (data.exportLineItems.csv) {
        FileSaver.saveAs(new Blob([data.exportLineItems.csv]), fileName)
      }
      dismissAllDialogs()
    } catch (error) {
      dismissAllDialogs()
      enqueueSnackbar('Error exporting line items', { variant: 'error' })
      Sentry.captureException(error)
    }
  }

  function handleReorder(lineItemOptions: LineItemOption[]) {
    const updatedOptions = reorderLineItemOptions({
      lineItemOptions,
      options,
      quoteType
    })
    setFieldValue('options', updatedOptions)
  }

  function handleEditItem(event: React.MouseEvent, key: string) {
    const items = getLineItemOptions({ options, quoteType, inactive: true })
    const item = items.find((i) => i.name === key)

    console.log({ item })
    openDialog(EditLineItemOptionDialog, {
      item,
      onConfirm: async (updatedOptions: OptionsDocument, isDirty?: boolean) => {
        const items = getLineItemOptions({ options: updatedOptions, quoteType, inactive: true })
        const updatedItem = items.find((i) => i.name === key)
        console.log({ updatedItem })
        dismissDialog()
        if (onDialogConfirm && isDirty) {
          setFieldValue('options', updatedOptions)
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  async function handleCreateItem(_event: React.MouseEvent) {
    const item = createLineItemOption({ quoteType })

    openDialog(EditLineItemOptionDialog, {
      item,
      isNew: true,
      onConfirm: async (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        if (onDialogConfirm) {
          onDialogConfirm()
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteItem(event: any, key: string) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>This line item will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getLineItemOptions({ options, quoteType, inactive: true })
        const updatedOptions = deleteLineItemOption({ lineItemOption: items.find((i) => i.name === key), options })

        setFieldValue('options', updatedOptions)
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleDeleteBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>These line items will no longer be available to add to new {objectLabels.quote.plural}.</p>
          <p>Existing {objectLabels.quote.plural} will not be affected.</p>
          <p>
            Please note, <strong>this can not be undone</strong>
          </p>
        </>
      ),
      onConfirm: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()

        const items = getLineItemOptions({ options, quoteType, inactive: true })
        const updatedOptions = deleteLineItemOptions({
          lineItemOptions: items.filter((item) => keys.includes(item.name)),
          options
        })

        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => {
        dismissDialog()
      }
    })
  }

  function handleAssignBulkItems(event: React.MouseEvent, keys: ValueType[]) {
    openDialog(BulkTypeAssignDialog, {
      keys,
      field: 'lineItems',
      options,
      onConfirm: (updatedOptions: OptionsDocument) => {
        dismissDialog()
        setFieldValue('options', updatedOptions)
        setSelected([])
      },
      onCancel: (_ev: React.MouseEvent<HTMLElement>) => dismissDialog()
    })
  }

  function handleCopyItem(event: any, key: string) {
    const updatedOptions = copyLineItemOption({ options, key })
    setFieldValue('options', updatedOptions)
  }
}

export default LineItemOptions
