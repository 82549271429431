/**
 * Calculate the dimensions of a substrate
 */
export const calculateDimensionValues = ({
  length,
  width,
  height,
  isRoom, // is the area the substrate is in a room, or a single wall?
  useInches = false
}: {
  length: number
  width: number
  height: number
  isRoom: boolean
  useInches?: boolean
}) => {
  let lnft = isRoom ? (length + width) * 2 : length
  let sqft = length * height
  let sqftWalls = (length + width) * 2 * height
  let sqftFloor = length * width
  let sqftCeiling = length * width
  let cuft = length * width * height

  if (useInches) {
    lnft = lnft / 1728
    sqft = sqft / 1728
    sqftWalls = sqftWalls / 1728
    sqftFloor = sqftFloor / 1728
    sqftCeiling = sqftCeiling / 1728
    cuft = cuft / 1728
  }

  return { lnft, sqft, sqftWalls, sqftFloor, sqftCeiling, cuft }
}
