import type { AreaSubstrate, QuoteArea, QuoteDocument } from 'paintscout'
import { parseNumber } from '../util/parse-number'
import { roundHours } from '../util/round-hours'
import { roundPriceQuoteOptions } from '../util'
import { productVolume } from './product-volume'
import { getQuoteOptions } from '../../builder/quote/util/get-options/get-quote-options'
import { getSubstrateDimension } from './get-substrate-dimension'

export function valuePerHourSubstrate(substrate: AreaSubstrate, area: QuoteArea, quote: QuoteDocument) {
  const { roundPrices, roundProducts, individualProductRounding } = getQuoteOptions({ quote }) ?? {}
  const hourlyRate = parseNumber(
    substrate.overrideHourlyRate && typeof substrate.hourlyRate === 'number' ? substrate.hourlyRate : quote.hourly_rate
  )
  const rateType = substrate.rate.rate_type
  const rateValue = parseNumber(substrate.rate_value)
  const prep = parseNumber(substrate.prep)
  const coats = parseNumber(substrate.coats)
  const products = [...(substrate?.product ? [substrate.product] : []), ...(substrate?.products ?? [])]
  const quantity = rateType === 'quantity' ? parseNumber(substrate.quantity, 1) : 1
  const dimension = getSubstrateDimension({ substrate, area, quote, rateType, quantity, calcType: 'value-per-hour' })

  productVolume(
    quote,
    products,
    rateType,
    parseNumber(dimension),
    coats,
    quantity,
    roundPrices,
    individualProductRounding ? roundProducts : 'none'
  )

  const hours = rateValue ? (parseNumber(dimension) / rateValue) * quantity : 0
  const roundedHours = roundHours(hours, quote)
  const defaultHours = roundedHours
  let calculationHours = defaultHours
  if (substrate.override_hours) {
    if (typeof substrate.override_hours_value !== 'undefined' && substrate.override_hours_value !== null) {
      calculationHours = substrate.override_hours_value
    } else {
      calculationHours = substrate.hours
    }
  }

  let defaultPrice = hourlyRate * (calculationHours + prep)
  let price = defaultPrice
  if (substrate.override_price) {
    if (typeof substrate.override_price_value !== 'undefined' && substrate.override_price_value !== null) {
      price = substrate.override_price_value
    } else {
      price = substrate.price
    }
  }

  defaultPrice = roundPriceQuoteOptions(defaultPrice, quote)

  return {
    ...substrate,
    default_hours: defaultHours,
    default_price: defaultPrice,
    price
  }
}
