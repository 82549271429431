import { useApolloClient } from '@apollo/react-hooks'
import type { User, UserQuery, UserQueryVariables } from '@paintscout/api'
import { useSearchUsersQuery } from '@paintscout/api'
import { UserDocument } from '@paintscout/api'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Spinner, UserTiles } from '@ui/paintscout'
import { addAuth0Prefix, removeAuth0Prefix } from '@paintscout/util/users'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'

export interface ChangeEstimatorDialogProps extends DialogProps {
  onConfirm?: (user?: User) => void
  onCancel?: () => void
  queryOverride?: string
  currentEstimatorId?: string
}

/**
 * Select new estimator for a quote, this is user facing user search, non-admin version
 * @returns User if found, null if none selected
 */
function ChangeEstimatorDialog(props: ChangeEstimatorDialogProps) {
  const { onCancel, queryOverride = '', onConfirm, currentEstimatorId = '', ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const apolloClient = useApolloClient()
  const [selectedEstimator, setSelectedEstimator] = useState(
    currentEstimatorId ? addAuth0Prefix(currentEstimatorId) : ''
  )
  const [fetchingEstimator, setFetchingEstimator] = useState(false)

  const { loading, data, error } = useSearchUsersQuery({
    variables: {
      query: queryOverride
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (error) {
      console.log(error)
      Sentry.captureException(error)
      enqueueSnackbar('Unable to retrieve Estimators', { variant: 'error' })
    }
  }, [error])

  const users = data?.searchUsers?.rows ?? []

  const initialEstimator = useMemo(
    () => users.find((user) => removeAuth0Prefix(user.user_id) === removeAuth0Prefix(currentEstimatorId)),
    [users]
  )

  return (
    <Dialog open={open} maxWidth="md" {...baseDialogProps}>
      <DialogTitle loading={fetchingEstimator}>Change Estimator</DialogTitle>
      <DialogContent>
        {loading && !users.length ? (
          <Spinner fullWidth fullHeight />
        ) : (
          <UserTiles
            disabled={fetchingEstimator}
            // always keep initial owner at beginning of list so it's not buried further down the dialog
            allowNone={true}
            users={[
              ...(initialEstimator ? [initialEstimator] : []),
              ...users.filter((user) => {
                const val =
                  removeAuth0Prefix(user.user_id) !== removeAuth0Prefix(initialEstimator?.user_id ?? '') &&
                  !((user.app_metadata?.roles ?? []).includes('viewer') && (user.app_metadata.roles ?? []).length === 1)

                return val
              })
            ]}
            selectedUserIds={selectedEstimator ? [selectedEstimator] : []}
            onUserSelect={async (user) => {
              setSelectedEstimator(user?.user_id ?? null)
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        leftButton={
          <Button onClick={onCancel} variant={'text'}>
            Cancel
          </Button>
        }
      >
        <Button
          type="submit"
          variant={'contained'}
          disabled={fetchingEstimator}
          onClick={async () => {
            if (selectedEstimator) {
              setFetchingEstimator(true)
              const { data } = await apolloClient.query<UserQuery, UserQueryVariables>({
                query: UserDocument,
                variables: {
                  id: selectedEstimator
                }
              })

              if (data?.user) {
                onConfirm(data.user)
              } else {
                enqueueSnackbar('An error occurred while loading the Estimator', { variant: 'error' })
                setFetchingEstimator(false)
              }
            } else {
              onConfirm(null)
            }
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeEstimatorDialog
