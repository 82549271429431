import type {
  AppMetadata,
  AppMetadataClaims,
  Auth0User,
  Permission,
  UserMetadata,
  UserMetadataClaims,
  UserPreferencesDocument
} from 'paintscout'
import React from 'react'

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<RecursivePartial<U>> // eslint-disable-next-line
    : T[P] extends object
      ? RecursivePartial<T[P]>
      : T[P]
}

// legacy
export interface UserProfile {
  _id: string
  firstName: string
  lastName: string
  phoneNumber: string
  title?: string
  email: string
  preferences: UserPreferencesDocument
  roles?: string[]
  active?: boolean
  company?: AppMetadata['company']
}

export type Auth0UserWithClaims = Auth0User & {
  app_metadata?: AppMetadata & RecursivePartial<AppMetadataClaims>
  user_metadata?: UserMetadata & RecursivePartial<UserMetadataClaims>
}

export interface UserValue {
  user?: Auth0UserWithClaims
  preferences?: UserPreferencesDocument
  profile?: UserProfile
  highestRole?: string
  isDirty?: boolean
  isSuperadmin?: boolean
  isSupport?: boolean
  isAdmin?: boolean
  isSales?: boolean
  isCrew?: boolean
  isViewer?: boolean
  isCorporate?: boolean
  isSubscribed?: boolean
  hasPermissions?: (permissions: Permission[], any?: boolean) => boolean
  updateTrialExpired?: (date: number) => void
  isTrial?: boolean
  billingId?: string
  nonBilling?: boolean
  trialExpired?: boolean
  trialExpiry?: number
  remainingQuotes?: number
  paymentExpiryNotice?: boolean
  isMasquerading?: boolean
  updatePaymentExpiryNotice?: (paymentExpiryNotice: boolean) => Promise<void>
}

const UserContext = React.createContext<UserValue>({})

export default UserContext
