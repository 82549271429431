import {
  Alert,
  Button,
  FormikCheckbox,
  // FormControl,
  FormikDatePicker,
  // FormikDropdownSelect,
  FormikInputField,
  // FormLabel,
  FormSection,
  FormSectionTitle,
  Grid,
  Hidden,
  Typography
} from '@ui/paintscout'

import type { ClientMetaDocument } from 'paintscout'
// import { getPlans } from '@paintscout/util/builder'
import { Field, useFormikContext } from 'formik'
import React from 'react'
import { useAuth } from 'react-auth0'
import ClientSubscriptionCard from './ClientSubscriptionCard'

export interface ClientBillingProps {}

const ClientBilling = () => {
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument }>()
  const clientBillingId = meta?.billingId
  const { session } = useAuth()
  const isSuperadmin = session?.app_metadata?.roles?.includes('superadmin')
  return (
    <>
      <FormSectionTitle title="Billing" />
      <FormSection>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Field
              fullWidth={true}
              options={plans}
              label="Plan"
              name="meta.plan"
              component={FormikDropdownSelect}
              disabled={true}
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            {clientBillingId && <ClientSubscriptionCard clientBillingId={clientBillingId} />}
          </Grid>
          {meta.paymentExpiryNotice && (
            <Grid item xs={12}>
              <Alert severity={'warning'}>
                <Typography variant="body1">
                  <strong>Client payment method flagged for expiry.</strong> Please have client update payment method.
                </Typography>
                <Field label="Payment Expiry Flag" name="meta.paymentExpiryNotice" component={FormikCheckbox} />
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <Field
              fullWidth={true}
              label="Billing ID"
              name="meta.billingId"
              component={FormikInputField}
              disabled={!meta.active || !isSuperadmin}
            />
          </Grid>
          {meta.billingId && (
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                href={`https://${process.env.CHARGEBEE_SITE}.chargebee.com/subscriptions?view_code=all&Subscriptions.search=${meta.billingId}`}
              >
                View on Chargebee
              </Button>
            </Grid>
          )}
          <Hidden smDown>
            <Grid item md={4} />
          </Hidden>
          <Grid item xs={12} md={4}>
            <Field
              fullWidth={true}
              label="Parent Company ID"
              name="meta.parentCompanyId"
              component={FormikInputField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field label="Non-Billing" name="meta.nonBilling" component={FormikCheckbox} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              InputProps={{
                fullWidth: true,
                label: 'Trial Expiry',
                sublabel: 'Trials expire at 12:00 GMT'
              }}
              format={'YYYY-MM-DD'}
              clearable
              name="meta.trialExpiry"
              component={FormikDatePicker}
            />
          </Grid>
          {meta.trialExpiry === null && meta.nonBilling && (
            <Grid item xs={12}>
              <Alert
                severity={'warning'}
                content={
                  'Are you sure? If Trial Expiry is null and Non-Billing is selected this client will have perpetual free access.'
                }
              />
            </Grid>
          )}
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientBilling
