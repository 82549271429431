import React, { useEffect, useState } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  useClientOptions,
  FormSection,
  EventSubscriptionType,
  Checkbox,
  FormControlLabel,
  Collapse,
  DropdownSelect,
  Typography
} from '../../'
import { getObjectLabels } from '@paintscout/util/builder'
import type { EventType, EventTypeConfig, ProviderConfig } from 'paintscout'
import { ProviderFormSection } from './ProviderFormSection'
import { useProcessEvent } from '@ui/core'
import classnames from 'classnames'

const useStyles = makeStyles<Theme, XeroProviderFormProps>(
  (theme) => ({
    root: {},
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    select: {
      width: 225,
      [theme.breakpoints.down('md')]: {
        width: 200
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    radioWrapper: {
      marginLeft: theme.spacing(5)
    },
    itemSelection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    itemSelectText: {
      minWidth: '140px',
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
        minWidth: '70px'
      }
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
        marginTop: -10
      }
    },
    required: {
      color: theme.palette.error.main
    }
  }),
  { name: 'XeroProviderForm' }
)

export interface XeroProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
}

export function XeroProviderForm(props: XeroProviderFormProps) {
  const { providerConfig, providerName, isSettingsIntegration = false } = props
  const classes = useStyles(props)

  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const [loading, setLoading] = useState(false)
  const [paymentAccountOptions, setPaymentAccountOptions] = useState([])
  const { enabled, config } = providerConfig
  const { processEvent } = useProcessEvent()

  useEffect(() => {
    async function load() {
      if (enabled) {
        setLoading(true)
        const configItemsRes = await processEvent({
          provider: providerName,
          type: 'get-config-items',
          params: {}
        })
        setPaymentAccountOptions(configItemsRes?.result?.accountItems ?? [])

        setLoading(false)
      }
    }
    load()
  }, [enabled])

  const handleEventChange = (name: EventType, event: EventTypeConfig) => {
    props.onChange(providerName, {
      ...providerConfig,
      events: {
        ...providerConfig.events,
        [name]: event
      }
    })
  }

  const DropdownItemSelection = (props: {
    name: string
    items: Array<{ value: number | string; label: string }>
    required?: boolean
    searchable?: boolean
  }) => {
    const { name, items, searchable, required } = props
    const selectedItem = items.filter((item) => item.value === config[name]?.value)[0] ?? null
    return (
      <DropdownSelect
        style={{ alignSelf: 'flex-start' }}
        name={name}
        searchable={searchable}
        disabled={loading}
        value={selectedItem}
        required={required}
        onChange={(option) => handleDropdownSelection(option, name)}
        options={items}
        className={classes.select}
        placeholder={{
          plural: 'Select Item...'
        }}
        variant={'single'}
      />
    )
  }
  const handleDropdownSelection = (option: any, name: string) => {
    let item = null
    if (name === 'paymentAccountCode') {
      item = { value: option.value }
    }
    if (name === 'itemAccountCode') {
      item = { value: option.value }
    }

    props.onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig.config,
        [name]: item
      }
    })
  }

  const paymentAccountRequired =
    (providerConfig?.events?.['quote-sent']?.enabled ||
      providerConfig?.events?.['quote-accepted']?.enabled ||
      providerConfig?.events?.['invoice-sent']?.enabled ||
      providerConfig?.events?.['quote-status-changed']?.enabled) &&
    !providerConfig?.config?.paymentAccountCode &&
    providerConfig?.config?.includePayments

  const itemAccountRequired =
    (providerConfig?.events?.['quote-sent']?.enabled ||
      providerConfig?.events?.['quote-accepted']?.enabled ||
      providerConfig?.events?.['invoice-sent']?.enabled ||
      providerConfig?.events?.['quote-status-changed']?.enabled) &&
    !providerConfig?.config?.itemAccountCode

  return (
    <FormSection hideDivider={true} style={{ paddingBottom: 0 }}>
      <ProviderFormSection
        title={'Create Quote'}
        rightContent={
          <>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                disableRadio={isSettingsIntegration}
                name={'quote-sent' as EventType}
                event={providerConfig.events['quote-sent']}
                onChange={handleEventChange}
                label={`When ${objectLabels.quote.plural} are sent`}
              />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                disableRadio={isSettingsIntegration}
                name={'quote-accepted' as EventType}
                event={providerConfig.events['quote-accepted']}
                onChange={handleEventChange}
                label={`When ${objectLabels.quote.plural} are accepted`}
              />
            </Grid>
          </>
        }
      />
      <ProviderFormSection
        title={'Create Invoice'}
        rightContent={
          <>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                disableRadio={isSettingsIntegration}
                name={'invoice-sent' as EventType}
                event={providerConfig.events['invoice-sent']}
                onChange={handleEventChange}
                label={`When Invoices are sent`}
                selfLabel={'My Invoices'}
                allLabel={'All Invoices'}
              />
            </Grid>
            <Grid item xs={12} className={classes.marginBottom}>
              <EventSubscriptionType
                disableRadio={isSettingsIntegration}
                name={'quote-status-changed' as EventType}
                event={providerConfig.events['quote-status-changed']}
                onChange={handleEventChange}
                label={`When Invoice is changed to paid status`}
                selfLabel={'My Invoices'}
                allLabel={'All Invoices'}
              />
            </Grid>
          </>
        }
      />
      <ProviderFormSection
        title={'Account'}
        subTitle={'Select the payment account in Xero that the integration will link to.'}
        required={!!providerConfig?.config?.includePayments && !providerConfig.config.paymentAccountCode}
        rightContent={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControlLabel
              label={''}
              control={
                <div className={classes.itemSelection}>
                  <Typography
                    className={classnames({
                      [classes.itemSelectText]: true,
                      [classes.required]: itemAccountRequired
                    })}
                  >
                    Item Account:
                  </Typography>
                  <DropdownItemSelection
                    required={itemAccountRequired}
                    name={'itemAccountCode'}
                    items={paymentAccountOptions}
                    searchable
                  />
                </div>
              }
            />
          </div>
        }
      />
      <ProviderFormSection
        title={'Terms'}
        subTitle={`By default, Xero terms are applied to ${objectLabels.quote.value.toLowerCase()} and invoices created. Check this box to transfer your PaintScout terms. Please note that Xero limits terms to 4000 characters; longer terms will be shortened.`}
        rightContent={
          <FormControlLabel
            label={'Include Paintscout Terms'}
            control={
              <Checkbox
                checked={!!providerConfig?.config?.includeTerms}
                onChange={() => {
                  props.onChange(providerName, {
                    ...providerConfig,
                    config: { ...providerConfig.config, includeTerms: !providerConfig?.config?.includeTerms }
                  })
                }}
              />
            }
          />
        }
      />
      <ProviderFormSection
        title={'Payments'}
        subTitle={
          <>
            Check this box if you want payments in PaintScout to transfer to Xero invoices. Please note you need to{' '}
            <a href="https://go.xero.com/GeneralLedger/ChartOfAccounts.aspx" target="_blank" rel="noreferrer">
              enable payments on your Xero account
            </a>{' '}
            for this to be successful
          </>
        }
        required={!!providerConfig?.config?.includePayments && !providerConfig.config.paymentAccountCode}
        rightContent={
          <>
            <FormControlLabel
              label={'Add Payments'}
              control={
                <Checkbox
                  checked={!!providerConfig?.config?.includePayments}
                  onChange={() => {
                    props.onChange(providerName, {
                      ...providerConfig,
                      config: { ...providerConfig.config, includePayments: !providerConfig?.config?.includePayments }
                    })
                  }}
                />
              }
            />
            <Collapse show={providerConfig.config.includePayments}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  label={''}
                  control={
                    <div className={classes.itemSelection}>
                      <Typography
                        className={classnames({
                          [classes.itemSelectText]: true,
                          [classes.required]: paymentAccountRequired
                        })}
                      >
                        Payment Account:
                      </Typography>
                      <DropdownItemSelection
                        required={paymentAccountRequired}
                        name={'paymentAccountCode'}
                        items={paymentAccountOptions}
                        searchable
                      />
                    </div>
                  }
                />
              </div>
            </Collapse>
          </>
        }
      />
      <ProviderFormSection
        title={'Numbers'}
        subTitle={`By default Xero will set quote and invoice numbers. Check this box to use PaintScout ${objectLabels.quote.value.toLowerCase()} and invoice ID numbers.`}
        rightContent={
          <FormControlLabel
            label={`Include Paintscout Numbers`}
            control={
              <Checkbox
                checked={!!providerConfig?.config?.includeNumbers}
                onChange={() => {
                  props.onChange(providerName, {
                    ...providerConfig,
                    config: { ...providerConfig.config, includeNumbers: !providerConfig?.config?.includeNumbers }
                  })
                }}
              />
            }
          />
        }
      />
    </FormSection>
  )
}
