import type { Theme } from '@material-ui/core'
import { gql } from 'apollo-boost'
import { useApolloClient } from '@apollo/react-hooks'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Spinner, Typography } from '@ui/paintscout'
import { Grid } from '@ui/paintscout'
// import FileSaver from 'file-saver'
import type { OptionsDocument, QuoteDocument } from 'paintscout'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import type { QuoteView } from '../../Quote'
import { getTemplatedFilename, getPdfBucketUrl } from '@paintscout/util/builder'
import { urlExists } from '@paintscout/util/builder/util/url-exists/url-exists'

export interface QuotePdfDialog extends Omit<DialogProps, 'message' | 'title' | 'loading'> {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  quote: QuoteDocument
  companyId: string
  quoteRev?: string
  options?: OptionsDocument
  silent?: boolean
  revision?: boolean
  view?: QuoteView
  custViewUrl?: string
  revisionQuoteUrl?: string
  paymentId?: string
  onConfirm?: () => void
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    input: {
      minWidth: 300,
      [theme.breakpoints.up('md')]: {
        minWidth: 350
      }
    }
  })

function QuotePdfDialog({
  open,
  quote,
  companyId,
  options,
  classes,
  onConfirm,
  quoteRev = quote._rev,
  paymentId = null,
  silent = false,
  revision = false,
  view = 'quote',
  custViewUrl = null,
  revisionQuoteUrl = null,
  ...props
}: QuotePdfDialog) {
  const [error, setError] = useState<{ error: any; message?: string }>(null)
  const apolloClient = useApolloClient()

  useEffect(() => {
    async function fetchData() {
      try {
        const { quotePdfUrl, quoteViewUrl, quotePdfFilename } = await getPdfUrl(quote, quoteRev, companyId)
        // Sub in our huge urlDoc for testing

        // Check if url exists before proceeding
        const exists = await urlExists(quotePdfUrl)
        if (exists) {
          await downloadPdf(quotePdfUrl, quotePdfFilename)
        } else {
          const { data } = await apolloClient.query({
            query: gql`
              query quotePdf($url: String!, $pdfUrl: String, $silent: Boolean, $revision: Boolean, $paymentId: String) {
                quotePdf(url: $url, pdfUrl: $pdfUrl, silent: $silent, revision: $revision, paymentId: $paymentId) {
                  quotePdfUrl
                }
              }
            `,
            variables: {
              url: quoteViewUrl,
              pdfUrl: quotePdfUrl,
              silent,
              revision,
              paymentId
            },
            fetchPolicy: 'network-only'
          })

          if (data.quotePdf.quotePdfUrl === 'ECONNABORTED') {
            // We timed out and will send link as email when uploaded async
            const customerViewNoEmail = custViewUrl && !quoteViewUrl.includes(':')
            const message = customerViewNoEmail
              ? `Looks like this is taking a little while... we'll send a message through the chat with a link to the PDF when it's done!`
              : `Looks like this is taking a little while... we'll email you a link to the PDF when it's done!`
            setError({
              error: 'ECONNABORTED',
              message
            })
          } else {
            await downloadPdf(data.quotePdf.quotePdfUrl, quotePdfFilename)
          }
        }
      } catch (error) {
        setError({ error })
      }
    }

    if (open) {
      fetchData()
    }
  }, [open])

  async function downloadPdf(quotePdfUrl, fileName) {
    try {
      // Download pdf from bucket and save
      const { data: fileData } = await axios({
        url: quotePdfUrl,
        method: 'get',
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'arraybuffer'
      })

      // With this url, download quote data to save
      // FileSaver.saveAs(new Blob([fileData], { type: 'application/pdf' }), `${fileName}`)
      // Alternative to FileSaver, make an anchor tag and click it instead, better cross browser compatibility
      const blob = new Blob([fileData], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName}`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
      if (onConfirm) {
        onConfirm()
      }
    } catch (error) {
      setError({ error })
      console.log('error', error)
    }
  }

  // Get our pdf url for this quoteId, rev, view, quoteUrl
  async function getPdfUrl(quote: QuoteDocument, quoteRev: string, companyId: string) {
    let quoteViewUrl: string
    let report: any
    if (custViewUrl) {
      // Avoid quoteUrl if in customer view and its given.
      quoteViewUrl = custViewUrl
    } else if (revisionQuoteUrl) {
      quoteViewUrl = revisionQuoteUrl
    } else {
      const { data } = await apolloClient.query({
        query: gql`
          query quoteUrl($id: String!, $rev: String!, $view: String!, $paymentId: String) {
            quoteUrl(id: $id, rev: $rev, view: $view, silent: true, paymentId: $paymentId) {
              url {
                _id
                report
              }
              quote
            }
          }
        `,
        variables: {
          id: quote._id,
          rev: quoteRev,
          view,
          paymentId
        }
      })

      const { quoteUrl } = data
      report = quoteUrl?.url?.report ?? null
      quoteViewUrl = quoteUrl?.url?._id ?? null
      console.log({ quoteUrl, quote: quoteUrl.quote.urls })
    }

    const quotePdfFilename = getTemplatedFilename({
      report,
      options: options.options,
      quote,
      view
    })
    const quotePdfUrl = getPdfBucketUrl({
      companyId,
      quoteRev,
      quoteId: quote._id,
      url: quoteViewUrl,
      view,
      filename: quotePdfFilename
    })

    return { quotePdfUrl, quoteViewUrl, quotePdfFilename }
  }
  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Download PDF</DialogTitle>
      <DialogContent>
        <div className={classes.root}>
          <Grid container spacing={2} alignItems={'center'} direction={'column'}>
            {!error && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom={true}>
                    Please wait while we generate your PDF.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Spinner />
                </Grid>
              </>
            )}
            {error && (
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom={true}>
                  {error.message ? error.message : 'Unable to generate PDF. Please try again later.'}
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions
        leftButton={
          !error && (
            <Button variant={'text'} onClick={onConfirm}>
              Cancel
            </Button>
          )
        }
      >
        {error && (
          <Button variant={'contained'} onClick={onConfirm}>
            Done
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(QuotePdfDialog)
