/**
 * Returns the highest role for a user, excluding superadmin.
 */
export function getHighestRole(roles: string[]) {
  if (roles.includes('superadmin')) {
    return 'superadmin'
  } else if (roles.includes('support')) {
    return 'support'
  } else if (roles.includes('corporate')) {
    return 'corporate'
  } else if (roles.includes('admin')) {
    return 'admin'
  } else if (roles.includes('sales')) {
    return 'sales'
  } else if (roles.includes('crew')) {
    return 'crew'
  } else if (roles.includes('viewer')) {
    return 'viewer'
  }
}
