import React, { useState } from 'react'
import Button from '../Button'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'
import { Collapse } from '@ui/core'
import { FilterChipGroup } from '../NewFilterChip'
import type { DropdownSelectOption } from './DropdownSelect'

const useStyles = makeStyles((theme: Theme) => ({
  margin: {
    marginTop: theme.spacing(1.5)
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[600],
    fontWeight: 500,
    padding: 0
  },
  chipGroup: {
    gridTemplateColumns: `repeat(auto-fill, minmax(120px, 1fr))`
  }
}))

interface DropdownChipsProps {
  options: DropdownSelectOption[]
  handleDelete: (option: DropdownSelectOption) => void
}

export default function DropdownChips({ options, handleDelete }: DropdownChipsProps) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  return (
    <div className={classes.margin}>
      <Button
        variant="text"
        size="small"
        className={classes.button}
        icon={open ? ExpandLessIcon : ExpandMoreIcon}
        flipIconSide
        iconSize="large"
        onClick={() => setOpen(!open)}
      >
        {open ? 'Show Less' : 'Show More'}
      </Button>
      <Collapse show={open}>
        <div className={classes.margin}>
          <FilterChipGroup
            classes={{ root: classes.chipGroup }}
            chips={options.map((option) => ({
              label: option.label,
              value: option.value,
              onDelete: () => handleDelete(option)
            }))}
          />
        </div>
      </Collapse>
    </div>
  )
}
