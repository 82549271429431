import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import SelectedIcon from '@material-ui/icons/CheckCircle'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import SelectableIcon from '@material-ui/icons/RadioButtonUnchecked'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import React from 'react'
import type { DropdownSelectOption } from '.'
import Grid from '../Grid'
import MenuItem from '../MenuItem'
import Typography from '../Typography'
import type { Variant as TypographyVariant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles<Theme, DropdownSelectMenuItemProps>((theme) => ({
  root: {
    margin: ({ searchable }) => (searchable ? theme.spacing(0.25, 2) : theme.spacing(0.25)),
    padding: theme.spacing(1, 1.5),
    backgroundColor: ({ selected }) => (selected ? theme.palette.action.hover : 'transparent')
  },
  label: {
    whiteSpace: 'initial',
    fontWeight: ({ bold }) => (bold ? 600 : 'normal')
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '22px'
  },
  icon: {
    fontSize: '1.1rem'
  },
  dot: {
    marginRight: theme.spacing(1)
  }
}))

export interface DropdownSelectMenuItemProps {
  option: DropdownSelectOption
  selected: boolean
  showSelectableIcon?: boolean
  typographyVariant?: TypographyVariant
  searchable?: boolean
  bold?: boolean
  formatOptionLabel?: (option: DropdownSelectOption) => ReactNode
  onClick: () => void
}

export default function DropdownSelectMenuItem(props: DropdownSelectMenuItemProps) {
  const { option, selected, typographyVariant = 'body1', showSelectableIcon, formatOptionLabel, onClick } = props
  const classes = useStyles(props)

  return (
    <MenuItem
      disabled={option.disabled}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      className={classes.root}
      value={option.value}
    >
      <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
        {option.dotColor && (
          <div className={classNames(classes.iconContainer, classes.dot)}>
            <DotIcon fontSize="small" style={{ color: option.dotColor }} />
          </div>
        )}
        <Grid item xs={10}>
          {formatOptionLabel && !!formatOptionLabel(option) ? (
            formatOptionLabel(option)
          ) : (
            <Typography variant={typographyVariant} className={classes.label}>
              {option.label}
            </Typography>
          )}
        </Grid>
        <div className={classes.iconContainer}>
          {selected && <SelectedIcon className={classes.icon} />}
          {showSelectableIcon && !selected && <SelectableIcon className={classes.icon} />}
        </div>
      </Grid>
    </MenuItem>
  )
}
