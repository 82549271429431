import Grid from '@material-ui/core/Grid'
import { DropdownSelect, DateRangePicker } from '@ui/paintscout'
import type { InFilter, RangeFilter, ValueFilter } from 'json-to-lucene'
import moment from 'moment'
import React from 'react'
import { getPlans } from '@paintscout/util/builder'
import type { DropdownSelectOption } from '@paintscout/ui/src/DropdownSelect'
import { handleFilterChange, handleRangeFilterChange } from '@paintscout/util/src/util'
import { FEATURE_OPTIONS } from '@paintscout/ui/src/dialogs/FeatureSelectDialog'

export interface ClientSearchFiltersProps {
  filters: ValueFilter[]
  onChange?: (filters: ValueFilter[]) => any
}

export const PLAN_OPTIONS = getPlans()
  .filter((plan) => !plan.value.includes('addon-user-monthly'))
  .concat([
    { value: 'custom', label: 'Custom', price: '0', features: {} },
    { value: 'non-billing', label: 'Non-Billing', price: '0', features: {} }
  ])

const ACTIVE_OPTIONS: DropdownSelectOption[] = [
  { label: 'No Active', value: ['false'] },
  { label: 'Include Active', value: ['true', 'false'] },
  { label: 'Only Active', value: ['true'] }
]

const DELETE_OPTIONS: DropdownSelectOption[] = [
  { label: 'No Delete', value: ['false'] },
  { label: 'Include Delete', value: ['true', 'false'] },
  { label: 'Only Delete', value: ['true'] }
]

const INTEGRATION_OPTIONS: DropdownSelectOption[] = [
  { label: 'DropBox', value: 'dropbox' },
  { label: 'Freshbooks', value: 'freshbooks' },
  { label: 'Sage', value: 'sage' },
  { label: 'CompanyCam', value: 'companycam' },
  { label: 'Quickbooks', value: 'intuit' },
  { label: 'Workglue', value: 'workglue' },
  { label: 'Pipeline CRM', value: 'pld' },
  { label: 'Xero', value: 'xero' },
  { label: 'Stripe', value: 'stripe' },
  { label: 'Wisetack', value: 'wisetack' },
  { label: 'Service Titan', value: 'serviceTitan' },
  { label: 'Paintscout Api Keys', value: 'paintscoutApiKeys' },
  { label: 'Zendesk', value: 'getbase' }
]

export default function ClientSearchFilters({ filters, onChange }: ClientSearchFiltersProps) {
  const expiryFilter = filters.find((filter) => filter.id === 'trialExpiry') as RangeFilter
  const planFilter = filters.find(({ id }) => id === 'plan') as InFilter
  const activeFilter = filters.find(({ id }) => id === 'active') as InFilter
  const deleteFilter = filters.find(({ id }) => id === 'delete') as InFilter
  const featureFilter = filters.find(({ id }) => id === 'features') as InFilter
  const integrationFilter = filters.find(({ id }) => id === 'options.integrations') as InFilter

  return (
    <Grid container spacing={2} alignItems={'flex-start'}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="plan"
              label="Plan"
              placeholder={{
                selectPlaceholder: 'Any Plan'
              }}
              variant="multi"
              options={PLAN_OPTIONS}
              value={PLAN_OPTIONS.filter((option) => {
                if (!planFilter) return false
                return planFilter?.value.includes(option.value)
              })}
              onChange={(option: DropdownSelectOption[]) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                handleFilterChange(
                  {
                    id: 'plan',
                    type: 'equal',
                    value: value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="active"
              label="Active"
              variant="single"
              options={ACTIVE_OPTIONS}
              value={ACTIVE_OPTIONS.find((option) => {
                if (!activeFilter) return false

                return option.value[0] === activeFilter?.value[0] && option.value[1] === activeFilter?.value[1]
              })}
              onChange={(option: DropdownSelectOption) => {
                handleFilterChange(
                  {
                    id: 'active',
                    type: 'equal',
                    value: option.value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="delete"
              label="Delete"
              variant="single"
              options={DELETE_OPTIONS}
              value={DELETE_OPTIONS.find((option) => {
                if (!deleteFilter) return false

                return option.value[0] === deleteFilter?.value[0] && option.value[1] === deleteFilter?.value[1]
              })}
              onChange={(option: DropdownSelectOption) => {
                handleFilterChange(
                  {
                    id: 'delete',
                    type: 'equal',
                    value: option.value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <DateRangePicker
          startDate={expiryFilter?.value[0] ? moment(expiryFilter.value[0]) : null}
          endDate={expiryFilter?.value[1] ? moment(expiryFilter.value[1]) : null}
          inputProps={{
            fromLabel: 'Expiry Start',
            toLabel: 'Expiry End',
            spacing: 2
          }}
          PopperProps={{
            placement: 'bottom-end'
          }}
          onConfirm={(start, end) => {
            handleRangeFilterChange({
              id: 'trialExpiry',
              start: start ? start.unix() * 1000 : null,
              end: end ? end.unix() * 1000 : null,
              filters,
              onChange
            })
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="features"
              label="Features"
              searchable
              placeholder={{
                selectPlaceholder: 'Any Features'
              }}
              variant="multi"
              options={FEATURE_OPTIONS}
              value={FEATURE_OPTIONS.filter((option) => {
                if (!featureFilter) return false
                return featureFilter?.value.includes(option.value)
              })}
              onChange={(option: DropdownSelectOption[]) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                console.log({ value })
                handleFilterChange(
                  {
                    id: 'features',
                    type: 'equal',
                    value: value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="options.integrations"
              label="Integrations"
              searchable
              placeholder={{
                selectPlaceholder: 'Any Integrations'
              }}
              variant="multi"
              options={INTEGRATION_OPTIONS}
              value={INTEGRATION_OPTIONS.filter((option) => {
                if (!integrationFilter) return false
                return integrationFilter?.value.includes(option.value)
              })}
              onChange={(option: DropdownSelectOption[]) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                handleFilterChange(
                  {
                    id: 'options.integrations',
                    type: 'equal',
                    value: value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DropdownSelect
              name="options.integrations"
              label="Integrations"
              searchable
              placeholder={{
                selectPlaceholder: 'Any Integrations'
              }}
              variant="multi"
              options={INTEGRATION_OPTIONS}
              value={INTEGRATION_OPTIONS.filter((option) => {
                if (!integrationFilter) return false
                return integrationFilter?.value.includes(option.value)
              })}
              onChange={(option: DropdownSelectOption[]) => {
                const value = option.map((vals) => (vals?.value ? vals.value : vals))
                handleFilterChange(
                  {
                    id: 'options.integrations',
                    type: 'equal',
                    value: value
                  },
                  filters,
                  onChange
                )
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
