import React from 'react'

import { getObjectLabels } from '@paintscout/util/builder'
import type { DropdownSelectOption, SingleSelectProps } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'
import Typography from '../Typography'
import { useClientOptions } from '../ClientOptionsProvider'

export interface CoatsSelectorProps extends Omit<SingleSelectProps, 'variant' | 'value' | 'options' | 'onChange'> {
  value?: number
  label?: string
  possibleValues?: number[]
  rateLabel?: string
  disabled?: boolean
  showRates?: boolean
  onChange?: (value: number) => void
}

export default function CoatsSelector({
  value,
  possibleValues = [],
  onChange,
  showRates = true,
  label = 'Coats',
  rateLabel,
  ...props
}: CoatsSelectorProps) {
  const { options } = useClientOptions()
  const { application } = getObjectLabels({ options })

  const dropdownOptions: DropdownSelectOption[] = possibleValues.map((item, index) => {
    const rateLabelStr = rateLabel ? ` (${item} ${rateLabel})` : ''
    const unitLabel = showRates ? ` ${application.plural}${rateLabelStr}` : ''
    return {
      label: `${index + 1}${unitLabel}`,
      value: item?.toString() ?? ''
    }
  })

  return (
    <DropdownSelect
      {...props}
      label={label}
      variant="single"
      value={dropdownOptions[value - 1]}
      options={dropdownOptions}
      onChange={(option) => onChange(dropdownOptions.indexOf(option) + 1)}
      formatOptionLabel={(option, placement) => {
        if (placement === 'selected') {
          return <Typography color="textPrimary">{value}</Typography>
        } else {
          return <Typography>{option.label}</Typography>
        }
      }}
    />
  )
}
