/**
 * @module builder
 */
import type { RatesDocument } from 'paintscout'
import type { EditableRate } from '../../index'
import { convertCalculationType, convertRateType, maybeParseFloat } from './helpers'

export function updateRateOption(args: { updatedRate: EditableRate; rates: RatesDocument }): RatesDocument {
  const { rates, updatedRate } = args

  const coverage = maybeParseFloat(updatedRate.coverage)
  const values = updatedRate.values.map((value) => {
    return maybeParseFloat(value)
  })

  return {
    ...rates,
    rates: {
      ...rates.rates,
      [updatedRate.name]: {
        ...rates.rates[updatedRate.name],
        name: updatedRate.name,
        active: typeof updatedRate.active !== 'undefined' ? updatedRate.active : true,
        int_ext: updatedRate.quoteType,
        label: updatedRate.label,
        notes: updatedRate.notes,
        client_label: updatedRate.clientLabel,
        default_applications: updatedRate.defaultApplications,
        default_length: maybeParseFloat(updatedRate.defaultLength),
        default_width: maybeParseFloat(updatedRate.defaultWidth),
        default_height: maybeParseFloat(updatedRate.defaultHeight),
        possible_applications: updatedRate.possibleApplications,
        values,
        prep: updatedRate.prep,
        coverage: !isNaN(coverage) ? coverage : null,
        rate_type: convertRateType(updatedRate.rateType),
        calculation_type: convertCalculationType(updatedRate.calculationType),
        order: updatedRate.order,
        section: updatedRate.rateSection,
        default_product: updatedRate.defaultProduct,
        default_products: (updatedRate?.defaultProducts ?? []).map((product) => ({
          ...product,
          coverage: maybeParseFloat(product.coverage)
        })),
        useCoats: updatedRate.useCoats,
        report_text: updatedRate.reportText,
        areaReportText: updatedRate.areaReportText,
        workOrderOnly: updatedRate.workOrderOnly,
        hourlyRate: updatedRate.hourlyRate,
        overrideHourlyRate: updatedRate.overrideHourlyRate,
        useInches: updatedRate.useInches,
        hideQuantity: updatedRate.hideQuantity,
        hideQuantityOnWorkOrder: updatedRate.hideQuantityOnWorkOrder,
        showProduct: updatedRate.showProduct,
        showCoats: updatedRate.showCoats,
        showPrep: updatedRate.showPrep,
        showPrice: updatedRate.showPrice,
        showDimensions: updatedRate.showDimensions,
        excludeDiscount: updatedRate.excludeDiscount,
        excludeTax: updatedRate.excludeTax
      }
    }
  }
}
