import type { AreaSubstrate, QuoteDocument, QuoteOptions } from 'paintscout'
import type { RenderableItemSubstrate } from '../..'
import type { UpdateableArea } from '../../../areas'
import { getAreaSubstrateKey } from '../../get-area-substrate-key'
import { isInAreaGroup } from '../../is-in-area-group'
import { isInSubstrateGroup } from '../../is-in-substrate-group'
import { getItemSection } from '../../../util'
import {
  convertAreaSubstrateProducts,
  convertCalculationType,
  convertDimensions,
  convertHours,
  convertMaterials,
  convertPrep,
  convertPrice,
  convertRateType
} from '../util'
import striptags from 'striptags'

/**
 * Trim an areaSubstrate to a RenderableItemSubstrate to be displayed
 */
export default function toRenderableItemSubstrate(args?: {
  areaSubstrate: AreaSubstrate
  options: QuoteOptions
  quote: QuoteDocument
  area: UpdateableArea
}): RenderableItemSubstrate {
  const { areaSubstrate, quote, area } = args

  const hours = convertHours(areaSubstrate)

  const prep = convertPrep(areaSubstrate)

  const totalHours = Number(prep) + Number(hours.useCustom ? hours.custom : hours.default)

  const price = convertPrice(areaSubstrate)

  const products = convertAreaSubstrateProducts(areaSubstrate)

  const materials = convertMaterials(products)

  const name = areaSubstrate.rate.label

  const { length, width, height } = convertDimensions({ areaSubstrate, area })

  const rateKey = getAreaSubstrateKey(quote, areaSubstrate)

  const itemSection = getItemSection(areaSubstrate)
  const inSubstrateGroup = isInSubstrateGroup({
    quote,
    areaSubstrateKey: rateKey,
    rateKey: areaSubstrate.key,
    section: itemSection
  })
  const inAreaGroup = !!isInAreaGroup({ quote, areaKey: area.key })
  const rateType = convertRateType(areaSubstrate.rate.rate_type)
  const calculationType = convertCalculationType(areaSubstrate.rate.calculation_type)
  const areaReportText =
    areaSubstrate.rate?.areaReportText && striptags(areaSubstrate.rate?.areaReportText).length > 0
      ? areaSubstrate.rate?.areaReportText
      : ''
  const substrateReportText =
    areaSubstrate.rate?.report_text && striptags(areaSubstrate.rate?.report_text).length > 0
      ? areaSubstrate.rate?.report_text
      : ''

  const dimensionOverride =
    areaSubstrate.override_dimensions && areaSubstrate.show_override_dimensions
      ? areaSubstrate.override_dimensions_value
      : null

  return {
    key: areaSubstrate.key,
    name: `${name}`,
    clientLabel: areaSubstrate.rate.client_label,
    rateKey,
    areaKey: area.key,
    inSubstrateGroup: !!inSubstrateGroup,
    inAreaGroup: !!inAreaGroup,

    notes: areaSubstrate.notes,

    hours,

    prep: Number(prep),
    totalHours,

    price,

    materials,

    products,

    coats: Number(areaSubstrate.coats),

    length,
    height,
    width,

    dimensionOverride,

    quantity: Number(areaSubstrate.quantity),

    rateType,
    section: areaSubstrate.rate.section,
    calculationType,

    workOrderOnly: areaSubstrate.workOrderOnly,

    showPrice: areaSubstrate.showPrice,
    showProduct: areaSubstrate.showProduct,
    showCoats: areaSubstrate.showCoats,
    showPrep: areaSubstrate.showPrep,
    showDimensions: areaSubstrate.showDimensions,
    useCoats: areaSubstrate.rate.useCoats,

    hideQuantity: areaSubstrate.hideQuantity,
    hideQuantityOnWorkOrder: areaSubstrate.hideQuantityOnWorkOrder,

    areaReportText,
    substrateReportText
  }
}
