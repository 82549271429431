import uniq from 'lodash/uniq'
import { formatCurrency } from '../../../util'
import type { OptionsDocument } from 'paintscout'

// Combine areaSubstrate labels to be rendered in the area  or substrate description
export default function mergeLabels({
  options,
  areaLabels,
  quantities,
  dimensions,
  prices,
  coats,
  products,
  prep
}: {
  options: OptionsDocument
  areaLabels: string[]
  quantities: { [value: string]: number }
  dimensions: {
    [value: string]: {
      value: number
      unit: string
    }
  }
  prices: { [value: string]: number }
  coats: { [value: string]: number }
  products: { [value: string]: string[] }
  prep: { [value: string]: number }
}): string[] {
  const res = uniq(areaLabels).map((label) => {
    const parenthesisItems = []

    if (dimensions[label]) {
      parenthesisItems.push(`${dimensions[label].value} ${dimensions[label].unit}`)
    }
    if (quantities[label]) {
      parenthesisItems.push(quantities[label])
    }
    if (prices[label]) {
      parenthesisItems.push(formatCurrency({ value: prices[label], options }))
    }
    if (coats[label]) {
      parenthesisItems.push(`${coats[label]} Coat${coats[label] === 1 ? '' : 's'}`)
    }
    if (prep[label]) {
      parenthesisItems.push(`${prep[label]} hr${prep[label] === 1 ? '' : 's'} prep`)
    }
    if (products[label]) {
      parenthesisItems.push(products[label].join(', '))
    }

    const filteredItems = parenthesisItems.filter((item) => item)

    return filteredItems.length ? `${label} (${filteredItems.join(', ')})` : label
  })

  return res
}
