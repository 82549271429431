import { Grid } from '@material-ui/core'
import {
  FormSection,
  FormSectionTitle,
  Typography,
  Spinner,
  Button,
  Checkbox,
  FormControlLabel,
  useDialogs
} from '@ui/paintscout'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import type { ApiKeyDocument, ClientMetaDocument, OptionsDocument } from 'paintscout'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { useGetApiKeysQuery, useGenerateApiKeyMutation, useUpdateApiKeyMutation } from '@paintscout/api'
import { ApiKey } from '@ui/paintscout'
import { ChangeEstimatorDialog } from '@paintscout/react-quote'
import omit from 'lodash/omit'

const useStyles = makeStyles<Theme>(
  (theme) => ({
    root: {},
    rowGrid: {
      display: 'flex',
      flexDirection: 'row'
    },
    mainFormSection: {
      padding: theme.spacing(2)
    },
    generateButton: {
      marginRight: theme.spacing(3),
      height: '41px'
    },
    infoContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing
    },
    keyContainer: {
      padding: theme.spacing()
    }
  }),
  { name: 'ClientApiKeys' }
)

export interface ClientApiKeysFormProps {
  classes?: StyleClasses<typeof useStyles>
}

const ClientApiKeys = (props: ClientApiKeysFormProps) => {
  const classes = useStyles(props)
  const [showArchived, setShowArchived] = useState(false)
  const {
    values: { meta }
  } = useFormikContext<{ meta: ClientMetaDocument; options: OptionsDocument }>()
  const [generateApiKey] = useGenerateApiKeyMutation()
  const [updateApiKey] = useUpdateApiKeyMutation()
  const [changeMade, setChangeMade] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { openDialog, dismissDialog } = useDialogs()

  const {
    data: apiKeysData,
    loading,
    error,
    refetch
  } = useGetApiKeysQuery({
    variables: {
      companyId: meta._id
    },
    fetchPolicy: changeMade ? 'network-only' : 'cache-and-network'
  })

  const handleNewKey = async () => {
    openDialog(ChangeEstimatorDialog, {
      queryOverride: `app_metadata.companyId:"${meta._id}" AND app_metadata.active:true`,
      onConfirm: async (user) => {
        try {
          await generateApiKey({
            variables: {
              companyId: meta._id,
              userId: user.user_id
            }
          })
          setChangeMade(true)
          refetch()
          enqueueSnackbar(`Successfully generated new apiKey`, { variant: 'success' })
        } catch (error) {
          console.log('error', error)
          enqueueSnackbar(`Error generating new apiKey ${error}`, { variant: 'error' })
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleEditApiKey = (apiKey: ApiKeyDocument) => {
    openDialog(ChangeEstimatorDialog, {
      queryOverride: `app_metadata.companyId:"${meta._id}" AND app_metadata.active:true`,
      currentEstimatorId: apiKey?.owner?.id,
      onConfirm: async (user) => {
        if (user.user_id !== apiKey?.owner?.id) {
          const res = await updateApiKey({
            variables: {
              apiKey: {
                ...omit(apiKey, ['__typename']),
                _id: apiKey._id,
                owner: {
                  id: user.user_id,
                  email: user.email,
                  firstName: user?.user_metadata?.firstName ?? user?.name?.split(' ')[0],
                  lastName: user?.user_metadata?.lastName ?? user?.name?.split(' ')[1],
                  phoneNumber: user?.user_metadata?.phoneNumber ?? ''
                }
              }
            }
          })
          if (res) {
            enqueueSnackbar(`Successfully updated apiKey`, { variant: 'success' })
            refetch()
          } else {
            enqueueSnackbar(`Error updating apiKey`, { variant: 'error' })
          }
        }
        dismissDialog()
      },
      onCancel: () => dismissDialog()
    })
  }

  return (
    <>
      <FormSectionTitle title="Api Keys" />
      <FormSection className={classes.mainFormSection}>
        <Grid container spacing={3}>
          {(loading || !apiKeysData?.getApiKeys) && (
            <Grid className={classes.infoContainer} container>
              <Grid item xs={12}>
                <Spinner />
              </Grid>
            </Grid>
          )}
          {error && (
            <Grid className={classes.infoContainer} container>
              <Grid item xs={12}>
                <Typography variant="h6">Error retrieving client Api Keys.</Typography>
              </Grid>
            </Grid>
          )}
          {apiKeysData && apiKeysData?.getApiKeys && (
            <Grid className={classes.infoContainer} container>
              <Grid item xs={12}>
                {apiKeysData?.getApiKeys?.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">Client has no Api Keys</Typography>
                  </Grid>
                ) : (
                  <Grid className={classes.keyContainer} container spacing={2} xs={12}>
                    {apiKeysData?.getApiKeys
                      .reduce((prev, current) => {
                        // List active keys first
                        if (current.archived && !showArchived) {
                          return prev
                        } else if (current.archived) {
                          prev.push(current)
                        } else {
                          prev.unshift(current)
                        }
                        return prev
                      }, [])
                      .map((apiKey) => {
                        return <ApiKey key={apiKey._id} apiKey={apiKey} onEdit={() => handleEditApiKey(apiKey)} />
                      })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button className={classes.generateButton} onClick={handleNewKey}>
              Generate Key
            </Button>
            <FormControlLabel
              control={
                <Checkbox value={showArchived} onClick={() => setShowArchived((showArchived) => !showArchived)} />
              }
              label={'Show Inactive Keys'}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientApiKeys
