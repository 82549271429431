import {
  FormikCheckbox,
  // FormLabel,
  FormSection,
  FormSectionTitle,
  Grid,
  Typography
} from '@ui/paintscout'

// import { getPlans } from '@paintscout/util/builder'
import { Field } from 'formik'
import React from 'react'

export interface ClientGamificationProps {}

const ClientGamification = () => {
  return (
    <>
      <FormSectionTitle title="Leaderboards" />
      <FormSection>
        <Grid container spacing={2}>
          <Grid item>
            <Field component={FormikCheckbox} name="meta.includeInLeaderboards" label={`Include in Leaderboards`} />
            <Typography variant="body2">
              Non-Billing & Sub-Accounts are not normally included in leaderboards. This checkbox will include them. It
              is not necessary for normally billed clients.
            </Typography>
          </Grid>
          <Grid item>
            <Field component={FormikCheckbox} name="meta.excludeFromLeaderboards" label={`Exclude from Leaderboards`} />
            <Typography variant="body2">
              Clients can be excluded from all leaderboard & gamification functionality with this checkbox. They will
              not be indexed or ranked as part of the leaderboard.
            </Typography>
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default ClientGamification
