import React, { useContext } from 'react'
import { Radio, RadioGroup, makeStyles } from '@material-ui/core'
import type { NotificationTypeConfig } from 'paintscout'
import UserContext from '../../UserProvider/UserContext'
import { NewFormSection as FormSection } from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import { getFeature, getObjectLabels } from '@paintscout/util/builder'
import { useClientOptions } from '../../ClientOptionsProvider'
import { connect, useFormikContext, Form } from 'formik'
import type { ProfileFormikValues } from '../ProfileFormik'
import FormControlLabel from '../../FormControlLabel'
import type { WithUseStyles } from '../../styles'
import { getHighestRole } from '@paintscout/util/users'
import Switch from '../../Switch'
import Checkbox from '../../Checkbox'
import { getIndefiniteArticle } from '@paintscout/util/util'
import SettingsPage from '../../SettingsPage'
import Typography from '../../Typography'

export interface NotificationsFormProps extends WithUseStyles<typeof useStyles> {
  showAdmin?: boolean
}

// keep this in sync with views/notification-subscribers.ts
// (ideally we keep this in a @paintscout/util function, but we can't use @paintscout/util functions in couchdb views)
// so it's best to be explicit both here and in the view
const defaultNotificationTypes: Record<string, NotificationTypeConfig> = {
  'quote-accepted': {
    enabled: false,
    triggeredBy: 'self'
  },
  'quote-declined': {
    enabled: false,
    triggeredBy: 'self'
  },
  'quote-additional-work-accepted': {
    enabled: false,
    triggeredBy: 'self'
  },
  'quote-viewed': {
    enabled: false,
    triggeredBy: 'self',
    frequency: 'firstView'
  },
  'quote-bounced': {
    enabled: false,
    triggeredBy: 'self'
  },
  'quote-message': {
    enabled: false,
    triggeredBy: 'self'
  },
  'quote-assigned': {
    enabled: false,
    triggeredBy: 'self'
  },
  'stats-digest-company': {
    enabled: false,
    triggeredBy: 'self'
  },
  'stats-digest-own': {
    enabled: false,
    triggeredBy: 'self'
  },
  'integration-quote-error': {
    enabled: false,
    triggeredBy: 'self'
  },
  'integration-contact-error': {
    enabled: false,
    triggeredBy: 'self'
  },
  'integration-authentication-error': {
    enabled: false,
    triggeredBy: 'self'
  },
  'payment-received': {
    enabled: false,
    triggeredBy: 'self'
  }
}

const useStyles = makeStyles((theme) => ({
  section: {},
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginTop: -10
    }
  },
  radio: {
    width: 160
  },
  dropdown: {
    // padding: theme.spacing(2, 2),
    // backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing()
    }
  },
  filled: {
    padding: theme.spacing(2, 2),
    width: 200
  },
  subheaderText: {
    padding: theme.spacing(1, 0)
  },
  subheaderDivider: {
    marginBottom: theme.spacing(2)
  },
  mobileLabelDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    marginLeft: theme.spacing(3)
  }
}))

function NotificationsForm(props: NotificationsFormProps) {
  const classes = useStyles(props)
  const { showAdmin } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { values, setFieldValue } = useFormikContext<ProfileFormikValues>()

  const highestRole = getHighestRole(values?.user?.app_metadata?.roles)
  const featureStatsDigest = getFeature({ options, path: 'notifications.statsDigest' })
  const featureQuoteViewed = getFeature({ options, path: 'notifications.quoteViewed' })
  const featureNotifications = getFeature({ options, path: 'notifications.enabled' })

  const showSalesAdminFeature = (feature: boolean) =>
    featureNotifications && feature && ['superadmin', 'admin', 'sales'].includes(highestRole)

  return (
    <SettingsPage
      title={`Notifications`}
      callout={{
        path: 'profile-notifications',
        content: `Customize your email notification preferences.`,
        learnMoreLink: 'http://help.paintscout.com/en/articles/5509580-setting-up-email-notifications'
      }}
    >
      <Form>
        <FormSection>
          <FormSectionTitle
            title={
              <Typography variant="h3">
                {`Responses to sent ${objectLabels.quote.plural.toLowerCase()}`}{' '}
                <Switch
                  onChange={() =>
                    setFieldValue(
                      'preferences.notifications.enabled' as any,
                      !values.preferences.notifications?.enabled
                    )
                  }
                  checked={values.preferences.notifications?.enabled}
                />
              </Typography>
            }
          />
          <NotificationOption
            type="quote-accepted"
            showAdmin={showAdmin}
            label={`${objectLabels.quote.value} Accepted`}
          />
          <NotificationOption
            type="quote-declined"
            showAdmin={showAdmin}
            label={`${objectLabels.quote.value} Declined`}
          />
          <NotificationOption
            type="quote-additional-work-accepted"
            showAdmin={showAdmin}
            label={`Additional Work Accepted`}
          />
          {showSalesAdminFeature(featureQuoteViewed) && (
            <NotificationOption
              type="quote-viewed"
              showFrequency
              trigger="self"
              label={`${objectLabels.quote.value} Viewed`}
            />
          )}
          <NotificationOption
            type="quote-bounced"
            showAdmin={showAdmin}
            label={`${objectLabels.quote.value} Could Not Be Delivered`}
          />
        </FormSection>
        <FormSection className={classes.section}>
          <FormSectionTitle title={`${objectLabels.quote.value} Chat`} variant={'h3'} />
          <NotificationOption type="quote-message" showAdmin={showAdmin} label={`New Messages`} trigger="self" />
        </FormSection>
        <FormSection className={classes.section}>
          <FormSectionTitle
            title={`${objectLabels.quote.value} Assignment`}
            variant={'h3'}
            toolTip={`Receive an email notification when ${getIndefiniteArticle(
              objectLabels.quote.value
            )} ${objectLabels.quote.value.toLowerCase()} has been assigned to you.`}
          />
          <NotificationOption type="quote-assigned" showAdmin={showAdmin} label={`On Assignment`} />
        </FormSection>
        {showAdmin && (
          <FormSection className={classes.section}>
            <FormSectionTitle title={`Integration Errors`} variant={'h3'} />
            <NotificationOption
              type="integration-quote-error"
              showAdmin={showAdmin}
              label={`Notification on Integration Quote Errors`}
            />
            <NotificationOption
              nameOverride={'Contacts'}
              type="integration-contact-error"
              showAdmin={showAdmin}
              label={`Notification on Integration Contact Errors`}
            />
            <NotificationOption
              nameOverride={'Authentication'}
              type="integration-authentication-error"
              showAdmin={showAdmin}
              label={`Notification on Integration Authentication Errors`}
            />
          </FormSection>
        )}
        <FormSection>
          <FormSectionTitle title={`Payments`} variant={'h3'} />
          <NotificationOption type="payment-received" label={`Payment Received`} />
        </FormSection>
        {showSalesAdminFeature(featureStatsDigest) && (
          <FormSection className={classes.section}>
            <FormSectionTitle
              title={`Leaderboards`}
              // subTitle={`Receive weekly summaries of the latest stats including win rate and ${objectLabels.currency.plural.toLowerCase()} won`}
              variant={'h3'}
            />
            <NotificationOption type="stats-digest-own" showAdmin={showAdmin} label={`Monthly Recap`} trigger="self" />
          </FormSection>
        )}
      </Form>
    </SettingsPage>
  )
}

interface NotificationOptionProps {
  type: string
  label: string
  nameOverride?: string
  showAdmin?: boolean
  showFrequency?: boolean
  /**
   * Forces the notification to a trigger (all/self)
   *
   * if not defined, then it will show the radio group to let the user select
   */
  trigger?: 'all' | 'self'
}
function NotificationOption(props: NotificationOptionProps) {
  const classes = useStyles(props)
  const { nameOverride } = props
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const { isAdmin, isViewer } = useContext(UserContext)
  const { values, setFieldValue } = useFormikContext<ProfileFormikValues>()
  const { preferences } = values
  const showTriggeredBy = (isAdmin || props.showAdmin || isViewer) && !props.trigger

  // get notification from options by type
  const notif =
    values.preferences.notifications &&
    values.preferences.notifications.types &&
    values.preferences.notifications.types[props.type]
      ? values.preferences.notifications.types[props.type]
      : defaultNotificationTypes[props.type]

  interface RadioSelectProps {
    target: 'triggeredBy' | 'frequency'
    label0: string
    label1: string
    val0: string
    val1: string
  }
  const RadioSelect = ({ target, label0, label1, val0, val1 }: RadioSelectProps) => {
    return (
      <RadioGroup
        className={classes.radioGroup}
        value={notif[target]}
        onChange={(ev, value: string) => {
          setFieldValue(`preferences.notifications.types.${props.type}.${target}` as any, value)
          setFieldValue(`preferences.notifications.types.${props.type}.enabled` as any, true)
        }}
      >
        <FormControlLabel
          className={classes.radio}
          label={label0}
          value={val0}
          disabled={!notif.enabled}
          control={<Radio color={'primary'} />}
        />
        <FormControlLabel
          className={classes.radio}
          label={label1}
          value={val1}
          disabled={!notif.enabled}
          control={<Radio color={'primary'} />}
        />
      </RadioGroup>
    )
  }

  return (
    <div className={classes.option}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!notif.enabled}
            onChange={(ev, value) => {
              if (
                // if props.trigger is set
                props.trigger ||
                // if preferences.notifications is undefined, default the triggeredBy value
                !preferences.notifications ||
                !preferences.notifications.types ||
                !preferences.notifications.types[props.type]
              ) {
                setFieldValue(
                  `preferences.notifications.types.${props.type}.triggeredBy` as any,
                  props.trigger || 'self'
                )
              }
              if (props.type === 'quote-viewed' && !preferences.notifications?.types?.['quote-viewed']) {
                // need to reset the default for this type's extra field else it is lost when notif switches from defaultNotificationTypes
                setFieldValue(`preferences.notifications.types.quote-viewed.frequency` as any, 'firstView')
              }

              setFieldValue(`preferences.notifications.types.${props.type}.enabled` as any, value)
            }}
          />
        }
        label={props.label}
      />
      {showTriggeredBy && (
        <RadioSelect
          target={'triggeredBy'}
          label0={`My ${nameOverride ? nameOverride : objectLabels.quote.plural}`}
          label1={`All ${nameOverride ? nameOverride : objectLabels.quote.plural}`}
          val0={'self'}
          val1={'all'}
        />
      )}
      {props.showFrequency && (
        <RadioSelect
          target={'frequency'}
          label0={'First View Only'}
          label1={'Each View'}
          val0={'firstView'}
          val1={'eachView'}
        />
      )}
    </div>
  )
}

export default connect<NotificationsFormProps, ProfileFormikValues>(NotificationsForm)
