import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import Grid from '../Grid'
import Typography from '../Typography'
import type { ApiKeyDocument } from 'paintscout'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useSnackbar } from 'notistack'
import Hidden from '../Hidden'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import classNames from 'classnames'

const useStyles = makeStyles<Theme, ApiKeyProps>(
  (theme) => ({
    root: {},
    apiKey: {
      border: '1px solid black',
      borderRadius: '5em',
      margin: `${theme.spacing(0.5)}px 0px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&.noOwner': {
        border: `1px solid ${theme.palette.error.main}`
      }
    },
    icon: {
      cursor: 'pointer',
      margin: '0.3em',
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    visIcon: {
      cursor: 'pointer',
      margin: '0.3em'
    },
    visIconHolder: {
      paddingLeft: theme.spacing(),
      display: 'flex',
      alignItems: 'center',
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    },
    archivedText: {
      color: theme.palette.error.main
    },
    activeText: {
      color: theme.palette.success.main
    },
    editDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    },
    copyClickText: {
      marginRight: theme.spacing(),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0
      }
    },
    revealDiv: {
      display: 'flex',
      alignItems: 'center',
      transition: 'transform 0.2s ease-in-out',
      '&.clicked': {
        transform: 'scale(0.95)'
      },
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main
      }
    }
  }),
  { name: 'ClientApiKey' }
)

export interface ApiKeyProps {
  classes?: StyleClasses<typeof useStyles>
  onEdit?: () => void
  apiKey: ApiKeyDocument
}

function ApiKey(props: ApiKeyProps) {
  const classes = useStyles(props)
  const { apiKey, onEdit } = props
  const [visible, setVisible] = useState<boolean>(false)
  const noOwner = !(apiKey?.owner?.firstName || apiKey?.owner?.lastName)
  const { enqueueSnackbar } = useSnackbar()
  const [clicked, setClicked] = useState(false)
  const toggleVisible = () => {
    setVisible((visible) => !visible)
  }

  const handleTextClick = () => {
    navigator.clipboard.writeText(apiKey._id)
    enqueueSnackbar(`Key Copied`, { variant: 'success' })
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
    }, 200)
  }

  return (
    <Grid className={classNames(classes.apiKey, { noOwner })} item container key={apiKey._id}>
      <Grid className={classes.visIconHolder} item xs={2}>
        {visible ? (
          <VisibilityOffIcon onClick={() => toggleVisible()} className={classes.visIcon} />
        ) : (
          <VisibilityIcon onClick={() => toggleVisible()} className={classes.visIcon} />
        )}
      </Grid>
      {visible ? (
        <Grid item xs={9} sm={10} onClick={handleTextClick} className={classNames(classes.revealDiv, { clicked })}>
          <Typography className={classes.copyClickText} variant="h6">
            **********-{apiKey._id.split('-').pop()}
          </Typography>
          <FileCopyIcon />
        </Grid>
      ) : (
        <>
          <Grid item xs={1} sm={3}>
            <Hidden xsDown>
              <Typography className={apiKey.archived ? classes.archivedText : classes.activeText} variant="h6">
                {apiKey.archived ? 'Archived' : 'Active'}
              </Typography>
            </Hidden>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">
              {noOwner ? 'No Owner' : `${apiKey?.owner?.firstName} ${apiKey?.owner?.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Hidden smDown>
              <Typography variant="h6">{new Date(parseInt(apiKey.created)).toLocaleDateString()}</Typography>
            </Hidden>
          </Grid>
          <Grid className={classes.editDiv} item xs={2}>
            {apiKey?.archived || !onEdit ? '' : <EditIcon className={classes.icon} onClick={() => onEdit()} />}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ApiKey
