import { calculateDimensionValues } from './calculate-dimension-values'

/**
 * Get the area of a substrate based on the dimensions and rate type, and if it is a room or a single wall
 */
export const getAreaSubstrateDimension = ({
  length,
  width,
  height,
  isRoom,
  rateType,
  useInches
}: {
  length: number
  width: number
  height: number
  isRoom: boolean
  rateType: string
  useInches?: boolean
}): number => {
  const {
    lnft: lnftValue,
    sqft: sqftValue,
    sqftWalls: sqftWallsValue,
    sqftFloor: sqftFloorValue,
    sqftCeiling: sqftCeilingValue,
    cuft: cuftValue
  } = calculateDimensionValues({
    length: length,
    width: width,
    height: height,
    isRoom,
    useInches
  })

  let dimensionValue = null
  switch (rateType) {
    case 'lnft':
      dimensionValue = lnftValue
      break
    case 'sqft':
      dimensionValue = sqftValue
      break
    case 'sqftWalls':
    case 'sqft_walls':
      dimensionValue = isRoom ? sqftWallsValue : sqftValue
      break
    case 'sqftFloor':
    case 'sqft_floor':
      dimensionValue = isRoom ? sqftFloorValue : sqftValue
      break
    case 'sqftCeiling':
    case 'sqft_ceiling':
      dimensionValue = isRoom ? sqftCeilingValue : sqftValue
      break
    case 'cuft':
      dimensionValue = cuftValue
      break
    default:
      dimensionValue = sqftValue
      break
  }

  return dimensionValue
}
