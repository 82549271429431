import type { GridSpacing } from '@material-ui/core'
import { ClickAwayListener, FormControl } from '@material-ui/core'
import type { PopperProps as MuiPopperProps } from '@material-ui/core/Popper'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { getRangePresetValue, getSelectedRangePreset } from '@paintscout/util/util'
import { useBreakpoint, useIsMobile } from '@ui/core'
import classnames from 'classnames'
import type { Moment } from 'moment'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import type { FocusedInputShape } from 'react-dates'
import { DayPickerRangeController } from 'react-dates'
import type { DropdownSelectOption } from '../DropdownSelect'
import DropdownSelectFooter from '../DropdownSelect/DropdownSelectFooter'
import Grid from '../Grid'
import Hidden from '../Hidden'
import IconButton from '../IconButton'
import Paper from '../Paper'
import ResponsivePopper from '../ResponsivePopper'
import { DatePickerInputs } from './DatePickerInputs'
import DatePickerMonthDropdown from './DatePickerMonthDropdown'
import DatePickerPresetChips from './DatePickerPresetChips'
import { useDatePickerStyles } from './useDatePickerStyles'

export interface DatePickerInputProps {
  spacing?: GridSpacing
  fromLabel?: string
  fromPlaceholder?: string
  toLabel?: string
  toPlaceholder?: string
}

export interface DateRangePickerProps {
  startDate?: Moment
  endDate?: Moment
  hidePresets?: boolean
  disabled?: boolean
  PopperProps?: Partial<MuiPopperProps>
  inputProps?: DatePickerInputProps
  dialogBreakPoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onConfirm: (startDate: Moment, endDate: Moment, selectedPresetKey?: string) => void
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const { disabled, hidePresets, PopperProps, inputProps, dialogBreakPoint = 'md', onConfirm } = props
  const mobile = useIsMobile()
  const isDialog = useBreakpoint((breakpoints) => breakpoints.down(dialogBreakPoint))

  const anchorRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [startDate, setStartDate] = useState<Moment>(props.startDate || null)
  const [endDate, setEndDate] = useState<Moment>(props.endDate || null)
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(null)

  const [tableHeight, setTableHeight] = useState(0)

  const recalculateTableHeight = () => {
    const elements = document.getElementsByClassName('CalendarMonthGrid_month__horizontal')
    if (elements.length > 0) {
      const height = (elements[0] as any).offsetHeight
      setTableHeight(height)
    }
  }

  useEffect(() => {
    recalculateTableHeight()
  }, [anchorEl])

  // If the prop dates are cleared, we need to clear the state dates as well
  useEffect(() => {
    if (!props.startDate && startDate) {
      setStartDate(null)
    }
    if (!props.endDate && endDate) {
      setEndDate(null)
    }
  }, [props.startDate, props.endDate])

  const classes = useDatePickerStyles({ startDate, endDate, tableHeight })

  const [selectedPreset, setSelectedPreset] = useState(getSelectedRangePreset(startDate, endDate))

  const handleSelectPreset = (option: DropdownSelectOption) => {
    const { from, to } = getRangePresetValue(option.value)
    setStartDate(from)
    setEndDate(to)
    setSelectedPreset(option)

    setAnchorEl(null)
    onConfirm(from, to, option.value)
    setFocusedInput(null)
  }

  const handleApply = () => {
    setAnchorEl(null)
    if (!endDate) {
      setEndDate(moment())
    }
    onConfirm(startDate, endDate || moment())
    setFocusedInput(null)
  }

  const handleClear = () => {
    setAnchorEl(null)
    setStartDate(null)
    setEndDate(null)
    setSelectedPreset(null)
    setFocusedInput(null)
    onConfirm(null, null)
  }

  const handleCancel = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (e.target instanceof HTMLElement && e.target.className.includes('top-navigation')) {
      // This means the click was in a nested dropdown, so we don't want to close the date picker
      return
    }
    setAnchorEl(null)
    setFocusedInput(null)
  }

  useEffect(() => {
    const grid = document.getElementsByClassName('CalendarMonthGrid')?.[0]
    if (grid) {
      ;(grid as any).ontransitionend = (e) => {
        e.stopPropagation()
      }
    }
  }, [focusedInput])

  function getInitialVisibleMonth() {
    if (endDate && focusedInput === 'endDate') {
      if (startDate.month() === endDate.month() && startDate.year() === endDate.year()) {
        return startDate
      }
      return mobile ? endDate : endDate.clone().subtract(1, 'month')
    }
    if (startDate) {
      return startDate
    }
    return moment()
  }

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <FormControl fullWidth>
        <Grid container spacing={inputProps?.spacing ?? 1} alignItems="flex-end">
          <DatePickerInputs
            anchorRef={anchorRef}
            focusedInput={focusedInput}
            startDate={startDate}
            endDate={endDate}
            disabled={disabled}
            anchorRefRight={PopperProps?.placement === 'bottom-end'}
            inputProps={inputProps}
            onStartClick={() => {
              setFocusedInput('startDate')
              if (!anchorEl) {
                setAnchorEl(anchorRef.current)
              }
            }}
            onEndClick={() => {
              setFocusedInput('endDate')
              if (!anchorEl) {
                setAnchorEl(anchorRef.current)
              }
            }}
            onAdornmentClick={handleApply}
          />
        </Grid>
        <ResponsivePopper
          classes={{
            root: classes.popper,
            dialogContent: classes.dialogContent,
            dialogRoot: classnames(classes.popper, classes.dialogMargin)
          }}
          open={!!anchorEl}
          PopperProps={{
            anchorEl,
            placement: 'bottom-start',
            modifiers: {
              flip: {
                enabled: false
              }
            },
            disablePortal: true,
            ...PopperProps
          }}
          modalBreakpoint={dialogBreakPoint}
          footer={<DropdownSelectFooter hideDivider onApply={handleApply} onClear={handleClear} />}
        >
          {isDialog && (
            <Grid className={classes.mobileInputs} container spacing={inputProps?.spacing ?? 1}>
              <DatePickerInputs
                focusedInput={focusedInput}
                startDate={startDate}
                endDate={endDate}
                disabled={disabled}
                onStartClick={() => {
                  setFocusedInput('startDate')
                  if (!anchorEl) {
                    setAnchorEl(anchorRef.current)
                  }
                }}
                onEndClick={() => {
                  setFocusedInput('endDate')
                  if (!anchorEl) {
                    setAnchorEl(anchorRef.current)
                  }
                }}
              />
            </Grid>
          )}
          {!hidePresets && (
            <DatePickerPresetChips selectedPreset={selectedPreset} onPresetSelect={handleSelectPreset} />
          )}
          <Paper className={classes.paper}>
            <Hidden smDown>
              {/* <div className={classes.monthTitles}>
                <Typography>from</Typography>
                <Typography>to</Typography>
              </div> */}
            </Hidden>
            <DayPickerRangeController
              transitionDuration={0}
              horizontalMonthPadding={40}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => {
                // If focusedInput is 'endDate' and we click on a date before the start date, the date comes in as
                // the start date for some reason.
                if (focusedInput === 'endDate' && startDate && !endDate) {
                  endDate = startDate.clone()
                  startDate = null
                }
                setStartDate(startDate)
                setEndDate(endDate)
                setSelectedPreset(null)
                setFocusedInput(!startDate ? 'startDate' : 'endDate')
                setSelectedPreset(getSelectedRangePreset(startDate, endDate))
              }}
              minimumNights={0}
              focusedInput={focusedInput}
              onFocusChange={(_) => {}}
              initialVisibleMonth={getInitialVisibleMonth}
              numberOfMonths={mobile ? 1 : 2}
              noBorder
              daySize={45}
              navPrev={
                <IconButton className={classes.navButton} size="small">
                  <ChevronLeftIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              navNext={
                <IconButton className={classnames(classes.navButton, classes.rightNavButton)} size="small">
                  <ChevronRightIcon fontSize={mobile ? 'default' : 'large'} />
                </IconButton>
              }
              onPrevMonthClick={() => {
                recalculateTableHeight()
              }}
              onNextMonthClick={() => {
                recalculateTableHeight()
              }}
              isDayHighlighted={(day) => day?.isSame(moment(), 'day')}
              renderMonthElement={(args) => <DatePickerMonthDropdown {...args} />}
            />
          </Paper>
        </ResponsivePopper>
      </FormControl>
    </ClickAwayListener>
  )
}
