import type { OptionsDocument, QuoteDocument } from 'paintscout'
import { getQuoteOptions } from '../../builder'
import { CORRECTIVE_CONSTANT } from '../../constants'

export function roundPriceQuoteOptions(value: number, quote: QuoteDocument, options?: OptionsDocument) {
  // checking if getQuoteOptions is defined because the alternative would be to mock it in a billion already written test files
  const roundPrices = getQuoteOptions ? getQuoteOptions({ options, quote })?.roundPrices : null
  if (roundPrices === 'round') return Math.round(value)
  if (roundPrices === 'up') return Math.ceil(value)
  if (quote) {
    return quote.version >= 6 ? Math.floor(value * 100 + CORRECTIVE_CONSTANT) / 100 : value
  } else {
    return Math.floor(value * 100 + CORRECTIVE_CONSTANT) / 100
  }
}
