import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import {
  FormikHtmlEditor,
  FormikInputField,
  FormikSwitch,
  PhoneNumberInput,
  Tab,
  Tabs,
  Typography,
  useClientOptions
} from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useDialogs } from '@ui/paintscout'
import SendIcon from '@material-ui/icons/Send'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import SplitterLayout from 'react-splitter-layout'
import * as Sentry from '@sentry/react'
import React, { useMemo, useState } from 'react'
import type { Payment, UserDocument, UserPreferencesDocument } from 'paintscout'
import { Grid, useIsMobile } from '@ui/core'
import { Formik, FieldArray, Field, FastField } from 'formik'
import {
  formatPhoneNumber,
  getEmailTemplates,
  getObjectLabels,
  getPayments,
  getQuoteOptions,
  reformatSpecialChars,
  setPayments
} from '@paintscout/util/builder'
import { useQuote } from '../../context'
import AddressBookSelect from '../../AddressBookSelect'
import { combineContexts, createQuoteContext, createUserContext, template } from '@paintscout/util/templater'
import { getTemplateTags } from '@paintscout/util/util'
import InfoIcon from '@material-ui/icons/ErrorOutline'
import { useQuoteMutations } from 'shared/hooks'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { ScaledQuote } from '../..'
import getRequestedPaymentsForPreview from '@paintscout/util/builder/quote/payments/get-requested-payments-for-preview/get-requested-payments-for-preview'
import striptags from 'striptags'

export const useStyles = makeStyles((theme: Theme) => ({
  rightTab: {
    marginRight: 0
  },
  sms: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  smsTo: {
    flexGrow: 1
  },
  smsSwitch: {
    marginRight: theme.spacing(),
    marginTop: 24
  },
  helpIcon: {
    marginRight: theme.spacing(1),
    width: 16
  },
  helpText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  paper: {
    '&:not($paperFullScreen)': {
      height: '80vh',
      maxWidth: '1200px'
    }
  },
  paperFullScreen: {},
  dialogContent: {
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0
  },
  splitPaneWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  splitterLayout: {
    '& .layout-splitter': {
      background: '#DDDDDD',
      opacity: 0.5,
      zIndex: 1,
      backgroundClip: 'padding-box',
      width: '12px',
      margin: '0 -5px',
      borderLeft: '5px solid rgba(255, 255, 255, 0)',
      borderRight: '5px solid rgba(255, 255, 255, 0)',
      cursor: 'col-resize'
    },
    '& .layout-pane:not(.layout-pane-primary)': {
      // fix scrollbar flickering on Scaler when there's a small amount of scrolling
      overflow: 'scroll'
    }
  },
  quotePaper: {
    boxShadow: 'none'
  },
  form: {
    marginRight: theme.spacing(),
    padding: theme.spacing()
  },
  secondaryAction: {
    height: theme.typography.pxToRem(50),
    borderTop: '1px #DDDDDD solid',
    margin: 0,
    paddingLeft: theme.typography.pxToRem(0),
    paddingRight: theme.typography.pxToRem(0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tabs: {
    marginLeft: -8,
    marginRight: -8
  },

  presentationWrapper: {
    paddingTop: theme.paintscout.header.height.md,
    pointerEvents: 'none'
  },
  previewToolbar: {
    width: '100%'
  }
}))
export interface SendPaymentRequestDialogProps extends DialogProps {
  payment: Payment
  user: UserDocument
  preferences: UserPreferencesDocument
  isResend?: boolean
}

const phoneRegExp =
  /^(([\\+]{0,1}[1-9]{1,4}[\s.\\-]{0,1})|(\\([0-9]{2,3}\\)[\s.\\-]{0,1})|([0-9]{2,4})[\s.\\-]{0,1})*?[0-9]{3,4}?[\s.\\-]{0,1}[0-9]{3,4}?$/
const ValidationSchema = Yup.object().shape({
  to: Yup.array().required('Required'),
  sms: Yup.object({
    to: Yup.string().when('enabled', {
      is: true,
      then: Yup.string()
        .matches(phoneRegExp, 'Please enter a valid phone number')
        .required(`Please enter a phone number`)
    })
  }),
  body: Yup.string().required('Required'),
  subject: Yup.string().required('Required')
})

export default function SendPaymentRequestDialog({
  payment,
  user,
  preferences,
  isResend,
  ...otherProps
}: SendPaymentRequestDialogProps) {
  const classes = useStyles()
  const { dismissDialog, dismissAllDialogs } = useDialogs()
  const mobile = useIsMobile()
  const { quote, save: saveQuote, updateQuote } = useQuote()
  const { requestPayment } = useQuoteMutations()
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState<'email' | 'preview'>('email')
  const { options } = useClientOptions()
  const { sendQuoteByTextDefault } = getQuoteOptions({ options, quote })

  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const emailTemplate = getEmailTemplates({ options, userPreferences: preferences })['payment-request']
  const templateContext = useMemo(
    () =>
      combineContexts(
        createQuoteContext({ quote, options }),
        createUserContext({ user: user?.user_metadata as UserDocument })
      ),
    [quote, options]
  )
  const subject = reformatSpecialChars(striptags(template({ context: templateContext, text: emailTemplate.subject })))

  const initialValues = {
    subject,
    body: template({ context: templateContext, text: emailTemplate.body }),
    sms: {
      enabled: sendQuoteByTextDefault,
      to: formatPhoneNumber({ options, phoneNumber: quote.contact?.phone_number })
    },
    to: quote.contact?.email ? [quote.contact.email] : [],
    from: quote.owner ? `${quote.owner.firstName} ${quote.owner.lastName}` : user.name
  }

  function mapAddressesToOptions(to: string[]) {
    return to.map((address) => ({
      value: address,
      label: address
    }))
  }

  const templateTags = getTemplateTags({ options })

  const previewPayments = useMemo(() => {
    return getRequestedPaymentsForPreview({ quote, previewPayment: payment })
  }, [])

  const preview = useMemo(() => {
    return (
      <div>
        <ScaledQuote
          quote={{
            ...quote,
            payments: previewPayments
          }}
          view={'quote'}
          classes={{ ...classes, paper: classes.quotePaper }}
        />
      </div>
    )
  }, [])

  return (
    <Dialog
      classes={{ paper: classes.paper, paperFullScreen: classes.paperFullScreen }}
      forceFullScreen={mobile}
      scroll="paper"
      fullWidth
      maxWidth="xl"
      {...otherProps}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={async (values) => {
          const payments = getPayments({ quote })
          const updatedPayments = payments.find((p) => p.id === payment.id) ? payments : [...payments, payment]
          const updatedQuote = setPayments({ quote, payments: updatedPayments, options, calculate: true })
          await saveQuote(updatedQuote)

          await requestPayment({
            quoteId: quote._id,
            paymentId: payment.id,
            email: {
              to: values.to,
              subject: values.subject,
              body: values.body,
              sms: values.sms.enabled ? { to: values.sms.to } : undefined
            }
          })
            .then((res) => {
              const updatedQuote = res.data?.requestPayment
              updateQuote({ quote: updatedQuote, blockDirty: true })
              enqueueSnackbar('Payment request sent', { variant: 'success' })
            })
            .catch((error) => {
              console.log(error)
              Sentry.captureException(error, { tags: { quoteId: quote._id } })
              enqueueSnackbar('Unable to send payment request', { variant: 'error' })
            })
            .finally(() => {
              dismissAllDialogs()
            })
        }}
      >
        {({ values, handleSubmit, isSubmitting, errors, touched }) => {
          const form = (
            <div className={classes.form}>
              <Grid container spacing={1} direction="column">
                <Grid item xs={12}>
                  <FieldArray
                    name={'to'}
                    render={(arrayHelpers) => (
                      <AddressBookSelect
                        label="To"
                        value={mapAddressesToOptions(values.to)}
                        required
                        fullWidth
                        searchable
                        onChange={(option: DropdownSelectOption[]) => {
                          arrayHelpers.form.setFieldValue(
                            'to',
                            option.map((o) => o.label)
                          )
                        }}
                      />
                    )}
                  />
                  {errors.to && touched.to && (
                    <Typography variant="caption" color="error">
                      {errors.to}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.sms}>
                    <Field
                      label="Send Text Message"
                      name="sms.to"
                      classes={{ root: classes.smsTo }}
                      disabled={!values.sms?.enabled}
                      component={FormikInputField}
                      inputComponent={PhoneNumberInput}
                      fullWidth
                      autoSelect
                    />
                    <Field name="sms.enabled" component={FormikSwitch} classes={{ root: classes.smsSwitch }} />
                  </div>
                  {errors.sms?.to && touched.sms?.to && (
                    <Typography variant="caption" color="error">
                      {errors.sms?.to as string}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field component={FormikInputField} label="From" name="from" disabled fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field component={FormikInputField} label="Subject" name="subject" fullWidth />
                  {errors.subject && touched.subject && (
                    <Typography variant="caption" color="error">
                      {errors.subject}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FastField
                    component={FormikHtmlEditor}
                    debounce
                    label={'Body'}
                    name="body"
                    margin="dense"
                    required
                    fullWidth
                    templateContext={templateContext}
                    toolbar={{
                      template: {
                        tooltip: `Insert ${objectLabels.quote.value} Info`,
                        tags: templateTags
                      }
                    }}
                  />
                  {errors.body && touched.body && (
                    <Typography variant="caption" color="error">
                      {errors.body}
                    </Typography>
                  )}
                </Grid>
                {!isResend && (
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.helpText}>
                      <InfoIcon className={classes.helpIcon} />
                      To edit the requested amount please visit the previous step.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          )

          return (
            <>
              <DialogTitle loading={isSubmitting}>Send Payment Request</DialogTitle>
              <DialogContent classes={{ root: classes.dialogContent }}>
                <div className={classes.splitPaneWrapper}>
                  {mobile ? (
                    <>
                      <Tabs
                        className={classes.tabs}
                        value={tab}
                        variant="fullWidth"
                        onChange={(ev, newTab) => setTab(newTab)}
                      >
                        <Tab value="email" label="Email" />
                        <Tab value="preview" label={objectLabels.quote.value} className={classes.rightTab} />
                      </Tabs>
                      {tab === 'email' && form}
                      {tab === 'preview' && preview}
                    </>
                  ) : (
                    <SplitterLayout
                      customClassName={classes.splitterLayout}
                      percentage
                      primaryMinSize={30}
                      secondaryMinSize={30}
                    >
                      {form}
                      {preview}
                    </SplitterLayout>
                  )}
                </div>
              </DialogContent>
              <DialogActions
                leftButton={
                  <Button disabled={isSubmitting} onClick={dismissDialog} variant="text">
                    {isResend ? 'cancel' : '< Back'}
                  </Button>
                }
              >
                <Button loading={isSubmitting} icon={SendIcon} onClick={() => handleSubmit()}>
                  Send Payment Request
                </Button>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}
