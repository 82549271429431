import type { PouchDocument, QuoteDocument } from 'paintscout'
import type { Contact } from '@paintscout/api'
import { removeAuth0Prefix } from '../../../users/removeAuth0Prefix/removeAuth0Prefix'

/**
 * Checks if the userID is the owner of the document
 */
export const isUserOwner = (
  doc: PouchDocument | Partial<QuoteDocument> | Partial<Contact>,
  userId: string
): boolean => {
  if (doc?.owner) {
    return doc.owner.id === removeAuth0Prefix(userId)
  }

  // no owner property
  return false
}
