import React from 'react'
import type { QuoteItemSection, RenderableItem } from '@paintscout/util/builder'
import { getFeature, getQuoteOptions } from '@paintscout/util/builder'
import { OptionsApprovalButton, Typography, useClientOptions, useUser } from '@ui/paintscout'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { HtmlContent } from '@ui/paintscout'
import NameColumn from './NameColumn'
import SplitAreaPrice from './SplitAreaPrice'
import { useQuote } from '../../context'
import classNames from 'classnames'

const useStyles = makeStyles<Theme, { hasOptionsApproval?: boolean }>((theme: Theme) => ({
  root: {},
  avoidPageBreak: {
    '@media print': {
      pageBreakInside: 'avoid'
    }
  },
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    '& span': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  stackedItemName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column'
    },
    '@media print': {
      flexDirection: 'row'
    }
  },
  firstLine: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  descriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  description: ({ hasOptionsApproval }) => {
    const nonOptionsApprovalStyles = {
      wordBreak: 'break-word',
      [theme.breakpoints.up('md')]: {
        maxWidth: 600
      },
      ['@media print']: {
        maxWidth: 600
      }
    }

    if (!hasOptionsApproval) {
      return nonOptionsApprovalStyles
    }

    const optionsApprovalStyles = {
      [theme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 128px)'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 94px)'
      }
    }

    return {
      ...nonOptionsApprovalStyles,
      ...optionsApprovalStyles
    }
  },

  priceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end'
  },
  justifyEnd: {
    display: 'flex',
    justifyContent: 'end'
  }
}))

export interface ItemColumnProps {
  item: RenderableItem
  showAmount?: boolean
  consumer: string
  isEditable: boolean
  section: QuoteItemSection
}

export default function ItemColumn({ item, isEditable, showAmount, consumer, section }: ItemColumnProps) {
  const { options } = useClientOptions()
  const { quote, isPresentation, onItemAction } = useQuote()
  const { value, name, description } = item
  const { isSuperadmin, isSupport } = useUser()

  const hasOptionsApproval = options?.options?.optionsApproval?.enabled

  const { splitAreaPrice, showDetailedBreakdown, stackSubstrates } = getQuoteOptions({ quote, options })

  const hasName = !!name
  const hasDescription =
    (description.useCustom && !!description.custom) || (!description.useCustom && !!description.default)

  const hasSubItems = item?.subItems && item?.subItems?.length > 0
  const showSplitAreaPrice = splitAreaPrice && item?.type === 'area' && consumer === 'customer'
  const backupPrice = item?.additionalFields?.price + item?.additionalFields?.materials
  const showOptionsApprovalButton =
    hasOptionsApproval &&
    isPresentation &&
    section.includes('options') &&
    !quote?.is_invoice &&
    quote?.status?.value !== 'accepted' &&
    !(quote?.calculationLock && (!isSuperadmin || !isSupport))
  const classes = useStyles({ hasOptionsApproval: hasOptionsApproval && showOptionsApprovalButton })

  const handleAddOption = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    if (!onItemAction) {
      return
    }

    if (section === 'added-options') {
      onItemAction('make-option', 'added-options', [item.key])
    } else {
      onItemAction('move-to-added-options', 'options', [item.key])
    }
  }

  // A little confusing, but this prevents the button appearing twice if two of the conditions are met.
  const getOptionsApprovalPlacement = (): 'first' | 'second' | 'third' | 'fourth' | 'fifth' => {
    if (!showOptionsApprovalButton) {
      return null
    }

    if (showDetailedBreakdown) {
      return 'first'
    }

    if ((!!value || !hasSubItems) && showAmount && !hasSubItems && !(hasName && hasDescription)) {
      return 'second'
    }

    if (hasSubItems && (!(hasName && hasDescription) || stackSubstrates)) {
      return 'third'
    }

    if (hasName && hasDescription && !showSplitAreaPrice) {
      return 'fourth'
    }

    if (showSplitAreaPrice) {
      return 'fifth'
    }
  }

  const optionsApprovalPlacement = getOptionsApprovalPlacement()
  const noPdfPageBreak = getFeature({ options, path: 'quotes.noPdfPageBreak' })

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.avoidPageBreak]: !noPdfPageBreak
      })}
    >
      <div className={classes.stackedItemName}>
        <NameColumn item={item} isEditable={isEditable} />
        {optionsApprovalPlacement === 'first' && <OptionsApprovalButton onClick={handleAddOption} section={section} />}
        {(!!value || !hasSubItems) &&
          showAmount &&
          (showSplitAreaPrice ? (
            <SplitAreaPrice areaKey={item.key} section={section} />
          ) : (
            <div className={classes.priceWrapper}>
              <Typography
                value={(item?.value === '' && !hasSubItems ? backupPrice : item?.value) as unknown as number}
                component="span"
                showUnits={consumer === 'crew' ? false : true}
                format={consumer === 'crew' ? 'hours' : 'price'}
                variant={'body1'}
                classes={{ root: classes.value }}
              />
              {optionsApprovalPlacement === 'second' && (
                <OptionsApprovalButton onClick={handleAddOption} section={section} padTop />
              )}
            </div>
          ))}
      </div>

      {hasSubItems && (
        <div>
          {(item?.subItems ?? []).map((subItem, index) => (
            <div key={index}>
              <div className={classes.firstLine}>
                <HtmlContent content={subItem.name ? subItem.name : subItem.description} />
                {subItem.value && (
                  <Typography
                    value={showAmount ? (subItem.value as unknown as number) : null}
                    format={consumer === 'crew' ? 'hours' : 'price'}
                    showUnits={consumer === 'crew' ? false : true}
                    variant={'body1'}
                    classes={{ root: classes.value }}
                  />
                )}
                {subItem.name && subItem.description && <div>{subItem.description}</div>}
                {optionsApprovalPlacement === 'third' && index === 0 && (
                  <OptionsApprovalButton onClick={handleAddOption} section={section} padTop />
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {hasName && hasDescription && (
        <div className={classes.descriptionWrapper}>
          <div className={classes.description}>
            <HtmlContent content={description.useCustom ? description.custom : description.default} />
          </div>
          {optionsApprovalPlacement === 'fourth' && (
            <OptionsApprovalButton onClick={handleAddOption} section={section} padTop />
          )}
        </div>
      )}

      {optionsApprovalPlacement === 'fifth' && (
        <div className={classes.justifyEnd}>
          <OptionsApprovalButton onClick={handleAddOption} section={section} padTop />
        </div>
      )}
    </div>
  )
}
