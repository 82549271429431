import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import {
  Collapse,
  EventSubscriptionType,
  FormSection,
  useClientOptions,
  useUser,
  InputField,
  Typography,
  FormControlLabel,
  DropdownSelect
} from '../../'
import type { EventType, ProviderConfig } from 'paintscout'
import { getObjectLabels, integrationEventHandlers } from '@paintscout/util/builder'
import { useFormikContext } from 'formik'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useAdminIntegrationsConfig, useProcessEvent } from '@ui/core'
import { ProviderFormSection } from './ProviderFormSection'
import find from 'lodash/find'

const useStyles = makeStyles<Theme, ServiceTitanProviderFormProps>(
  (theme) => ({
    root: {},
    visIcon: {
      cursor: 'pointer'
    },
    formSection: {
      paddingBottom: 0,
      paddingTop: theme.spacing(2)
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20
      }
    },
    radioWrapper: {
      marginLeft: theme.spacing(1.4)
    }
  }),
  { name: 'ServiceTitanProviderForm' }
)

export interface ServiceTitanProviderFormProps {
  classes?: StyleClasses<typeof useStyles>
  providerConfig: ProviderConfig
  providerName: string
  onChange: (providerName: string, providerConfig: ProviderConfig, save?: boolean) => void
  isSettingsIntegration?: boolean
  isAdminIntegrations?: boolean
  companyId?: string
  userId?: string
}

export function ServiceTitanProviderForm(props: ServiceTitanProviderFormProps) {
  const classes = useStyles(props)
  const {
    providerConfig,
    providerName,
    isSettingsIntegration = false,
    isAdminIntegrations = false,
    onChange,
    companyId = '',
    userId = ''
  } = props
  const { enabled, config } = providerConfig
  const {
    tenantId: providerConfigTenantId,
    clientId: providerConfigClientId,
    clientSecret: providerConfigClientSecret
  } = config
  const { preferences: userPreferences } = useUser()
  const preferences = isSettingsIntegration ? null : userPreferences
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const [visible, setVisible] = useState({
    tenantId: false,
    clientId: false,
    clientSecret: false
  })
  const [invalidTenantId, setInvalidTenantId] = useState(false)
  const { dirty: isDirty } = useFormikContext()
  const { adminIntegrationsConfig } = useAdminIntegrationsConfig()
  const { processEvent } = useProcessEvent()
  const [isLoading, setIsLoading] = useState(false)
  const [businessUnitOptions, setBusinessUnitOptions] = useState([])
  const [campaignOptions, setCampaignOptions] = useState([])
  const [jobTypesOptions, setJobTypesOptions] = useState([])
  const [serviceTypeOptions, setServiceTypeOptions] = useState([])
  //moment.js days of week selector
  const dayItems = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 30, label: 'End of Month' },
    { value: 365, label: 'End of Year' }
  ]

  const toggleVisible = (field: string) => {
    setVisible((prev) => ({
      ...prev,
      [field]: !prev[field]
    }))
  }

  const { handleEventChange } = useMemo(() => {
    return integrationEventHandlers({
      providerName,
      providerConfig,
      onChange
    })
  }, [providerName, providerConfig, onChange])

  const timeoutId = useRef<NodeJS.Timeout | null>(null)
  const tenantId = useRef<string>(
    isSettingsIntegration ? providerConfigTenantId : preferences?.integrations?.[providerName]?.config?.tenantId ?? ''
  )
  const clientId = useRef<string>(
    isSettingsIntegration ? providerConfigClientId : preferences?.integrations?.[providerName]?.config?.clientId ?? ''
  )
  const clientSecret = useRef<string>(
    isSettingsIntegration
      ? providerConfigClientSecret
      : preferences?.integrations?.[providerName]?.config?.clientSecret ?? ''
  )

  function handleTenantIdChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) {
    const { value } = ev.target
    const trimmedValue = value.trim()
    props.onChange(
      providerName,
      {
        ...providerConfig,
        enabled: true,
        config: {
          ...providerConfig.config,
          [field]: trimmedValue
        }
      },
      false
    )
    if (field === 'tenantId') {
      tenantId.current = trimmedValue
      // Clear the previous timeout, if any
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    } else if (field === 'clientId') {
      clientId.current = trimmedValue
    } else if (field === 'clientSecret') {
      clientSecret.current = trimmedValue
    }
  }

  async function load(key?: string) {
    if (enabled && (tenantId.current || key)) {
      const usedKey = key ?? tenantId.current
      if (isDirty && !timeoutId.current) {
        onChange(
          providerName,
          {
            ...providerConfig,
            enabled: true,
            config: {
              ...providerConfig?.config,
              tenantId: usedKey
            }
          },
          true
        )
        // Start a new timeout to block this from spamming
        const newTimeoutId = setTimeout(() => {
          clearTimeout(timeoutId.current)
          timeoutId.current = null
        }, 10000)

        timeoutId.current = newTimeoutId
      }
      setIsLoading(true)
      const configItemsRes = isAdminIntegrations
        ? await adminIntegrationsConfig({
            provider: providerName,
            type: 'get-config-items',
            params: {
              tenantId: usedKey,
              companyId,
              userId
            }
          })
        : await processEvent({
            provider: providerName,
            type: 'get-config-items',
            params: {
              tenantId: usedKey
            }
          })

      if (configItemsRes) {
        const { error } = configItemsRes
        if (configItemsRes?.result) {
          setBusinessUnitOptions(configItemsRes?.result?.businessUnitItems ?? [])
          setCampaignOptions(configItemsRes?.result?.campaignItems ?? [])
          setJobTypesOptions(configItemsRes?.result?.jobTypeItems ?? [])
          setServiceTypeOptions(configItemsRes?.result?.serviceTypeItems ?? [])
          setInvalidTenantId(false)
        }
        if (error?.includes('invalid request')) {
          setInvalidTenantId(true)
        }
      }
      setIsLoading(false)
    } else if (!tenantId.current && invalidTenantId) {
      setInvalidTenantId(false)
    }
  }

  useEffect(() => {
    load()
  }, [enabled, preferences?._rev])

  const DropdownItemSelection = (props: {
    name: string
    items: Array<{ value: number | string; label: string }>
    searchable?: boolean
  }) => {
    const { name, items, searchable } = props
    const selectedItem = items.filter((item) => item.value === config[name]?.value)[0] ?? null
    return (
      <DropdownSelect
        style={{ alignSelf: 'flex-end' }}
        name={name}
        searchable={searchable}
        disabled={isLoading}
        value={selectedItem}
        onChange={(option) => handleDropdownSelection(option, name)}
        options={items}
        className={classes.select}
        placeholder={{
          plural: 'Select Item...'
        }}
        variant={'single'}
      />
    )
  }
  const handleDropdownSelection = (option: any, name: string) => {
    let item = null
    if (name === 'businessUnitId') {
      item = find(businessUnitOptions, { value: option.value })
    }
    if (name === 'campaignTypeId') {
      item = find(campaignOptions, { value: option.value })
    }
    if (name === 'jobTypeId') {
      item = find(jobTypesOptions, { value: option.value })
    }
    if (name === 'materialServiceType' || name === 'laborServiceType') {
      item = find(serviceTypeOptions, { value: option.value })
    }
    if (name === 'appointmentDay') {
      item = find(dayItems, { value: option.value })
    }
    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig?.config,
        [name]: item
      }
    })
  }

  const handleItemSelection = (ev: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = ev.target
    const item = { value }

    onChange(providerName, {
      ...providerConfig,
      config: {
        ...providerConfig?.config,
        [name]: item
      }
    })
  }

  return (
    <>
      <FormSection hideDivider={true} className={classes.formSection}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputField
              fullWidth={true}
              label={'Tenant Id'}
              type={visible['tenantId'] ? 'text' : 'password'}
              value={tenantId.current}
              disabled={isAdminIntegrations}
              onChange={(ev) => handleTenantIdChange(ev, 'tenantId')}
              sublabel={`Found in your Service Titan Account ${invalidTenantId ? '- (Invalid Tenant Id)' : ''}`}
              error={invalidTenantId || !tenantId.current}
              startAdornment={
                invalidTenantId || isDirty ? (
                  <div onClick={() => load()}>
                    <RefreshIcon className={classes.visIcon} />
                  </div>
                ) : null
              }
              endAdornment={
                <div onClick={() => toggleVisible('tenantId')}>
                  {visible['tenantId'] ? (
                    <VisibilityOffIcon className={classes.visIcon} />
                  ) : (
                    <VisibilityIcon className={classes.visIcon} />
                  )}
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth={true}
              label={'Client Id'}
              type={visible['clientId'] ? 'text' : 'password'}
              value={clientId.current}
              disabled={isAdminIntegrations}
              onChange={(ev) => handleTenantIdChange(ev, 'clientId')}
              sublabel={`Found in your Service Titan Account`}
              error={!clientId.current}
              endAdornment={
                <div onClick={() => toggleVisible('clientId')}>
                  {visible['clientId'] ? (
                    <VisibilityOffIcon className={classes.visIcon} />
                  ) : (
                    <VisibilityIcon className={classes.visIcon} />
                  )}
                </div>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              fullWidth={true}
              label={'Client Secret'}
              type={visible['clientSecret'] ? 'text' : 'password'}
              value={clientSecret.current}
              disabled={isAdminIntegrations}
              onChange={(ev) => handleTenantIdChange(ev, 'clientSecret')}
              sublabel={`Found in your Service Titan Account`}
              error={!clientSecret.current}
              endAdornment={
                <div onClick={() => toggleVisible('clientSecret')}>
                  {visible['clientSecret'] ? (
                    <VisibilityOffIcon className={classes.visIcon} />
                  ) : (
                    <VisibilityIcon className={classes.visIcon} />
                  )}
                </div>
              }
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSection hideDivider={true} className={classes.formSection}>
        <ProviderFormSection
          title={'Create Estimates'}
          subTitle={`Create an Estimate in Service Titan when a specific event occurs. Or it can be triggered manually from the send button on the ${objectLabels.quote.value}.`}
          rightContent={
            <Grid item xs={12}>
              <Grid item xs={12}>
                <EventSubscriptionType
                  isAdminIntegrations={isAdminIntegrations}
                  disableRadio={isSettingsIntegration}
                  name={'quote-accepted' as EventType}
                  event={providerConfig?.events?.['quote-accepted']}
                  onChange={handleEventChange}
                  label={`When ${objectLabels.quote.plural} are accepted`}
                />
              </Grid>
              <Grid item xs={12}>
                <EventSubscriptionType
                  isAdminIntegrations={isAdminIntegrations}
                  disableRadio={isSettingsIntegration}
                  name={'quote-sent' as EventType}
                  event={providerConfig.events['quote-sent']}
                  onChange={handleEventChange}
                  label={`When ${objectLabels.quote.plural} are sent`}
                />
              </Grid>
              <Collapse
                show={
                  providerConfig?.events?.['quote-accepted']?.enabled || providerConfig?.events?.['quote-sent']?.enabled
                }
              >
                <Grid item classes={{ root: classes.radioWrapper }}>
                  <Typography variant={'subtitle1'}>Sell specific status only:</Typography>
                  <RadioGroup
                    className={classes.radioGroup}
                    name={'sellEstimateStatus'}
                    value={providerConfig?.config?.sellEstimateStatus?.value ?? null}
                    onChange={(ev) => {
                      if (handleItemSelection) {
                        handleItemSelection(ev)
                      }
                    }}
                  >
                    <FormControlLabel
                      label={`Sell Accepted/Invoiced Status`}
                      value={'accepted'}
                      disabled={!enabled}
                      control={<Radio color={'primary'} />}
                    />
                    <FormControlLabel
                      label={`Sell All Status`}
                      value={'all'}
                      disabled={!enabled}
                      control={<Radio color={'primary'} />}
                    />
                  </RadioGroup>
                  {!providerConfig?.config?.sellEstimateStatus?.value && (
                    <Typography style={{ color: 'red' }} variant={'subtitle2'}>
                      Must select option.
                    </Typography>
                  )}
                </Grid>
              </Collapse>
            </Grid>
          }
        />
        <ProviderFormSection
          title={'Create Invoices'}
          subTitle={`Create an Invoice in Service Titan when a specific event occurs. Or it can be triggered manually from the send button on the Invoice.`}
          rightContent={
            <Grid item xs={12}>
              <Grid item xs={12}>
                <EventSubscriptionType
                  isAdminIntegrations={isAdminIntegrations}
                  disableRadio={isSettingsIntegration}
                  name={'quote-paid' as EventType}
                  event={providerConfig.events['quote-paid']}
                  onChange={handleEventChange}
                  label={`When Invoice is fully paid`}
                />
              </Grid>
            </Grid>
          }
        />
        {providerConfig?.events?.['quote-paid']?.enabled && (
          <ProviderFormSection
            title={'Invoice Job Appointment Day'}
            subTitle={
              'Service Titan requires an appointment to be added to the job that is created for an Invoice. Select day of week you would like the appointment to be set for.'
            }
            required={
              (providerConfig?.events?.['quote-paid']?.enabled &&
                !providerConfig?.config?.appointmentDay?.value &&
                providerConfig?.config?.appointmentDay?.value !== 0) ||
              !dayItems.filter((item) => item.value === providerConfig.config?.appointmentDay?.value)[0]
            }
            rightContent={
              <div className={classes.itemSelection}>
                <Typography className={classes.itemSelectText}>Use day:</Typography>
                <DropdownItemSelection searchable name={'appointmentDay'} items={dayItems} />
              </div>
            }
          />
        )}
        <ProviderFormSection
          title={'Materials Service Type'}
          subTitle={'Service Titan Service Type to use when creating materials line items on Estimates and Invoices.'}
          required={
            !providerConfig?.config?.materialServiceType?.value ||
            !serviceTypeOptions.filter((item) => item.value === providerConfig.config?.materialServiceType?.value)[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use type:</Typography>
              <DropdownItemSelection searchable name={'materialServiceType'} items={serviceTypeOptions} />
            </div>
          }
        />
        <ProviderFormSection
          title={'Labor Service Type'}
          subTitle={'Service Titan Service Type to use when creating labor line items on Estimates and Invoices.'}
          required={
            !providerConfig?.config?.laborServiceType?.value ||
            !serviceTypeOptions.filter((item) => item.value === providerConfig?.config?.laborServiceType?.value)[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use type:</Typography>
              <DropdownItemSelection searchable name={'laborServiceType'} items={serviceTypeOptions} />
            </div>
          }
        />
        <ProviderFormSection
          title={'Business Unit Type'}
          subTitle={'Business Unit Type to use when creating Service Titan Job.'}
          required={
            !providerConfig?.config?.businessUnitId?.value ||
            !businessUnitOptions.filter((item) => item.value === providerConfig?.config?.businessUnitId?.value)[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use type:</Typography>
              <DropdownItemSelection searchable name={'businessUnitId'} items={businessUnitOptions} />
            </div>
          }
        />
        <ProviderFormSection
          title={'Job Type'}
          subTitle={'Job Type to use when creating Service Titan Job.'}
          required={
            !providerConfig?.config?.jobTypeId?.value ||
            !businessUnitOptions.filter((item) => item.value === providerConfig?.config?.jobTypeId?.value)[0]
          }
          rightContent={
            <div className={classes.itemSelection}>
              <Typography className={classes.itemSelectText}>Use type:</Typography>
              <DropdownItemSelection searchable name={'jobTypeId'} items={jobTypesOptions} />
            </div>
          }
        />
      </FormSection>
    </>
  )
}
