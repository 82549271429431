import type { AreaSubstrate, QuoteArea, QuoteDocument, RateType } from 'paintscout'
import { parseNumber } from '../util'

/**
 * Get the dimension value for a price-per-value or value-per-hour substrate
 */
export function getSubstrateDimension({
  substrate,
  area,
  quote,
  rateType,
  quantity,
  calcType
}: {
  substrate: AreaSubstrate
  area: QuoteArea
  quote: QuoteDocument
  rateType: RateType
  quantity: number
  calcType: string // price-per-value || value-per-hour
}) {
  const areaType = area.area_type.value || 'room'

  let dimension
  if (rateType === 'quantity') {
    dimension = quantity
  } else if (substrate.show_override_dimensions && substrate.override_dimensions) {
    // Area is room, but substrate is overridden to be lnft with an overridden value
    dimension = parseNumber(substrate.override_dimensions_value)
  } else if (
    substrate.override_dimensions ||
    (quote.version >= 2 && substrate.length && substrate.length !== area.dimensions.length) ||
    (quote.version >= 2 && substrate.width && substrate.width !== area.dimensions.width) ||
    (quote.version >= 2 && substrate.height && substrate.height !== area.dimensions.height)
  ) {
    // Substrate has overridden dimensions
    let length
    let height
    let width
    const pricePerValueCalc = calcType === 'price-per-value'
    // Get lwh for price per value calculations or value-per-hour calculations
    if (pricePerValueCalc) {
      length = parseNumber(substrate.length)
      height = parseNumber(substrate.height)
      width = parseNumber(substrate.width)
    } else {
      if (quote.version === 2 && !(areaType === 'room')) {
        // substrate
        length = parseNumber(substrate.length ? substrate.length : area.dimensions.length)
        height = parseNumber(substrate.height ? substrate.height : area.dimensions.height)
        width = parseNumber(substrate.width ? substrate.width : area.dimensions.width)
      } else {
        if (
          typeof substrate.override_length === 'undefined' ||
          typeof substrate.override_width === 'undefined' ||
          typeof substrate.override_height === 'undefined'
        ) {
          // this means it's an "old" room - before we allowed overriding
          const lengthVal = areaType === 'room' ? area.dimensions.length : substrate?.length ?? area.dimensions.length
          const heightVal = areaType === 'room' ? area.dimensions.height : substrate?.height ?? area.dimensions.height
          const widthVal = areaType === 'room' ? area.dimensions.width : substrate?.width ?? area.dimensions.width
          length = parseNumber(lengthVal)
          height = parseNumber(heightVal)
          width = parseNumber(widthVal)
        } else {
          const lengthVal =
            areaType === 'room'
              ? substrate.override_length && substrate.length
                ? substrate.length
                : area.dimensions.length
              : substrate.override_length
                ? substrate.length
                : area.dimensions.length
          const widthVal =
            areaType === 'room'
              ? substrate.override_width && substrate.width
                ? substrate.width
                : area.dimensions.width
              : substrate.override_width
                ? substrate.width
                : area.dimensions.width
          const heightVal =
            areaType === 'room'
              ? substrate.override_height && substrate.height
                ? substrate.height
                : area.dimensions.height
              : substrate.override_height
                ? substrate.height
                : area.dimensions.height

          length = parseNumber(lengthVal)
          width = parseNumber(widthVal)
          height = parseNumber(heightVal)
        }
      }
    }

    // Get the dimension based on the rate type
    if (rateType === 'lnft' && pricePerValueCalc) {
      dimension = length
    } else if (rateType === 'lnft') {
      dimension = areaType === 'room' ? (length + width) * 2 : length
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? (length + width) * 2 * height : length * height
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? length * width : length * height
    } else if (rateType === 'cuft') {
      dimension = length * width * height
    } else {
      dimension = length * height
    }
  } else {
    // No overridden dimensions so we use the area dimensions
    const lnft = parseNumber(area.dimensions.lnft)
    const sqft = parseNumber(area.dimensions.sqft)
    const sqftWalls = parseNumber(area.dimensions.sqft_walls)
    const sqftCeiling = parseNumber(area.dimensions.sqft_ceiling)
    const sqftFloor = parseNumber(area.dimensions.sqft_floor)
    const cuft = parseNumber(area.dimensions.cuft)

    if (rateType === 'lnft') {
      dimension = lnft
    } else if (rateType === 'cuft') {
      dimension = cuft
    } else if (rateType === 'sqft_walls') {
      dimension = areaType === 'room' ? sqftWalls : sqft
    } else if (rateType === 'sqft_ceiling') {
      dimension = areaType === 'room' ? sqftCeiling : sqft
    } else if (rateType === 'sqft_floor') {
      dimension = areaType === 'room' ? sqftFloor : sqft
    } else {
      dimension = sqft
    }
  }

  if (substrate.useInches) {
    dimension = dimension / 1728
  }

  return dimension
}
