import type { Contact } from '@paintscout/api'
import { getFeature, getFeatures, getQuoteOptions, hasIntegrationInterface } from '@paintscout/util/builder'
import { removeAuth0Prefix } from '@paintscout/util/users'
import { useConnection } from 'react-detect-offline'
import type {
  OptionsDocument,
  ProviderConfig,
  QuoteDocument,
  QuoteEventType,
  UserPreferencesDocument
} from 'paintscout'
import { useContext } from 'react'
import * as Sentry from '@sentry/react'
import type { WisetackPromoResponse } from './types'
import { useProcessEvent } from '@ui/core'
import type { ProcessEventResponse } from '@ui/core'
import { AlertDialog, DialogStackContext } from '@ui/paintscout'
import type { Auth0UserWithClaims } from '@ui/paintscout'
import { useSnackbar } from 'notistack'
import SendWisetackLinkDialog from './SendWisetackLinkDialog'
import InlineWisetackDialog from './InlineWisetackDialog'
import moment from 'moment'
import getDepositAmount from '@paintscout/util/util/getDepositAmount/getDepositAmount'
import { parseNumber } from '@paintscout/util/calculator'
import get from 'lodash/get'

interface PayoutStage {
  payoutAmount: number
  description: string
}

interface InitiateFinancing {
  contact: Contact | QuoteDocument['contact']
  phoneNumber?: string
  transactionAmount: number
  serviceCompletedOn: number
  appSource: string
  purchaseId: string
  invoiceNumber?: number
  quoteNumber?: number
  payouts?: PayoutStage[]
}

interface UseWisetack {
  hasWisetack: boolean
  merchantSignupDate: moment.Moment
  showOnOldDocuments: boolean
  maximumLoanAmount: number
  getPromo: (quote: QuoteDocument) => Promise<WisetackPromoResponse>
  handleTextLoanApplication: (quote: QuoteDocument) => Promise<void>
  handleGetLoanApplication: (quote: QuoteDocument) => Promise<void>
  handleSignup: (options: OptionsDocument) => Promise<void>
  getPayouts: (quote: QuoteDocument) => PayoutStage[]
  createSignupLink: () => Promise<ProcessEventResponse['processEvent']>
}

export function useWisetack({
  user,
  options,
  preferences
}: {
  user: Auth0UserWithClaims
  options: OptionsDocument
  preferences?: UserPreferencesDocument
}): UseWisetack {
  const { processEvent } = useProcessEvent()
  const { openDialog, dismissDialog, dismissAllDialogs } = useContext(DialogStackContext)
  const { enqueueSnackbar } = useSnackbar()
  const { online } = useConnection()
  const isAmerican =
    !options.options?.companyCountry?.value || options.options?.companyCountry?.value === 'United States'

  const hasWisetack: boolean =
    hasIntegrationInterface({ preferences, options, integration: 'wisetack' }) &&
    getFeature({ options, preferences, path: 'integrations.providers.wisetack' }) &&
    isAmerican

  const merchantSignupDate = options?.options?.wisetackSignup?.date
    ? moment(options?.options?.wisetackSignup?.date)
    : null

  const hasSettingsIntegrations = get(options?.options?.features, 'settingsIntegrations.enabled')
  const providerConfig = hasSettingsIntegrations
    ? (options?.options?.integrations?.['wisetack'] as ProviderConfig)
    : (preferences?.integrations?.['wisetack'] as ProviderConfig)

  const showOnOldDocuments = !!providerConfig?.config?.showOnOldDocuments
  // This is still TBD on Wisetack's end, so no clients have this set in their config yet
  const maximumLoanAmount = providerConfig?.config?.maximumLoanAmount || 25000

  async function getPromo(quote: QuoteDocument) {
    const shouldShowPromo = hasWisetack && !!quote && quote.totals.balance_due > 500
    if (!shouldShowPromo) {
      return null
    }

    const res = await processEvent({
      type: 'get-promo' as QuoteEventType,
      provider: 'wisetack',
      params: {
        amount: Math.min(maximumLoanAmount, quote?.totals?.balance_due),
        // avoid sending the quote id of a quote that hasn't yet been saved, it'll cause an error
        quoteId: quote._rev ? quote?._id : null
      }
    })
    if (res?.error) {
      Sentry.captureException(res.error, { tags: { quoteId: quote?._id } })
      return null
    } else if (res?.result?.result?.promo) {
      return res.result.result.promo as WisetackPromoResponse
    }
  }

  async function handleTextLoanApplication(quote: QuoteDocument) {
    if (!quote.contact) {
      openDialog(AlertDialog, {
        title: 'Cannot Text Financing Application',
        message: 'You must add a contact before you can send a Financing Application link',
        onConfirm: dismissDialog
      })
    } else {
      const mustApplyAgain = quote.contact?.wisetackLoanApplication?.approvedLoanAmount > quote.totals.balance_due
      openDialog(SendWisetackLinkDialog, {
        phoneNumber: quote.contact.phone_number,
        serviceDate: quote.dates?.completion * 1000,
        link: mustApplyAgain ? null : quote.contact?.wisetackLoanApplication?.loanApplicationLink,
        transactionAmount: quote.totals?.balance_due,
        onConfirm: async (phoneNumber: string, transactionAmount?: number, serviceCompletedOn?: number) => {
          await initiateFinancing({
            contact: quote.contact,
            transactionAmount,
            phoneNumber,
            serviceCompletedOn,
            appSource: `merchant-view-${quote.is_invoice ? 'invoice' : 'estimate'}`,
            purchaseId: quote._id,
            payouts: getPayouts(quote)
          })
            .then((response) => {
              if (!response || response?.error) {
                throw new Error(response?.error || 'Unable to send Application Link')
              } else {
                enqueueSnackbar('Financing Application link sent!', {
                  variant: 'success'
                })
              }
            })
            .catch((error) => {
              Sentry.captureException(error, { tags: { quoteId: quote._id } })
              enqueueSnackbar('Unable to send Application Link', { variant: 'error' })
            })
          dismissDialog()
        },
        onCancel: () => {
          dismissDialog()
        }
      })
    }
  }

  async function initiateFinancing(args: InitiateFinancing) {
    const { contact, phoneNumber, transactionAmount, serviceCompletedOn, appSource, purchaseId, payouts } = args

    return await processEvent({
      type: 'initiate-financing' as QuoteEventType,
      provider: 'wisetack',
      params: {
        transactionAmount,
        serviceCompletedOn,
        appSource,
        purchaseId,
        contactId: (contact as Contact)?._id || (contact as QuoteDocument['contact'])?.id,
        mobileNumber: phoneNumber,
        transactionPurpose: `Loan application of ${transactionAmount} sent to ${contact.first_name || ''} ${
          contact.last_name || ''
        } from ${options.options.companyName.value}`,
        quoteId: purchaseId,
        payouts
      }
    })
  }

  async function createSignupLink() {
    const { user_metadata } = user
    const {
      companyName,
      companyAddress,
      companyCity,
      companyEmail,
      companyProv,
      companyPhone,
      companyPostal,
      companyIndustry
    } = options.options

    return await processEvent({
      type: 'create-signup-link' as QuoteEventType,
      provider: 'wisetack',
      params: {
        initiator: {
          id: removeAuth0Prefix(user.user_id),
          firstName: user_metadata?.firstName,
          lastName: user_metadata?.lastName,
          email: user_metadata?.email,
          mobileNumber: user_metadata?.phoneNumber
        },
        business: {
          businessLegalName: companyName?.value,
          doingBusinessAs: companyName?.value,
          businessAddress: companyAddress?.value,
          email: companyEmail?.value,
          city: companyCity?.value,
          state: companyProv?.value,
          zip: companyPostal?.value,
          phoneNumber: companyPhone?.value,
          industry: companyIndustry?.value
        }
      }
    })
  }

  async function handleGetLoanApplication(quote: QuoteDocument) {
    const mustApplyAgain = quote.contact?.wisetackLoanApplication?.approvedLoanAmount > quote.totals.balance_due
    openDialog(
      InlineWisetackDialog,
      {
        link: mustApplyAgain ? null : quote?.contact?.wisetackLoanApplication?.loanApplicationLink,
        onConfirm: dismissDialog,
        createLink: async () => {
          return await initiateFinancing({
            contact: quote?.contact,
            transactionAmount: quote.totals.balance_due,
            serviceCompletedOn:
              quote.dates?.completion ||
              moment()
                .add(quote.is_invoice ? 0 : 1, 'days')
                .unix(),
            appSource: `customer-view-${quote.is_invoice ? 'invoice' : 'estimate'}`,
            purchaseId: quote._id,
            invoiceNumber: quote.is_invoice ? quote.number : null,
            quoteNumber: quote.is_invoice ? null : quote.number,
            payouts: getPayouts(quote)
          })
            .then((response) => {
              return response.result.result.paymentLink
            })
            .catch((error) => {
              dismissAllDialogs()
              console.log({ error })
              Sentry.captureException(error, { tags: { quoteId: quote._id } })
              enqueueSnackbar('Unable to get Application Link', { variant: 'error' })
            })
        }
      },
      {
        replace: true
      }
    )
  }

  async function handleSignup(options: OptionsDocument) {
    openDialog(
      InlineWisetackDialog,
      {
        link: options?.options?.wisetackSignup?.signupLink,
        onConfirm: dismissDialog,
        createLink: async () => {
          return await createSignupLink()
            .then((response) => {
              console.log({ response })
              if (!response || response?.error) {
                throw new Error(response?.error || 'Unable to get Signup Link')
              } else {
                return response.result.result.signupLink
              }
            })
            .catch((error) => {
              dismissDialog()
              console.log({ error })
              // Sentry.captureException(error, { tags: { quoteId: quote._id } })
              enqueueSnackbar('Unable to get Signup Link', { variant: 'error' })
            })
        }
      },
      {
        replace: true
      }
    )
  }

  function getPayouts(quote: QuoteDocument): PayoutStage[] {
    const { wisetackDepositAmount: depositOption } = getQuoteOptions({ quote, options })
    const stagedPayoutsFeature = getFeatures({ options, preferences })?.wisetackStagedPayouts

    const shouldSplitDeposit =
      !quote.is_invoice && !!depositOption?.value && quote.totals.amount_paid === 0 && !quote.depositInfo && online

    if (!shouldSplitDeposit || !stagedPayoutsFeature) {
      return null
    }

    const depositAmount = parseNumber(getDepositAmount({ quote, options, wisetack: true }).toFixed(2))
    const balanceDue = parseNumber(quote.totals.balance_due.toFixed(2))
    const remainder = parseNumber((balanceDue - depositAmount).toFixed(2))

    // Wisetack has a minimum payment amount of $500 and a maximum payment (usually $25000)
    // And the first cannot be more than 50% of the total
    if (depositAmount < 500 || depositAmount > maximumLoanAmount || depositAmount > balanceDue / 2) {
      return null
    }

    if (remainder < 500 || remainder > maximumLoanAmount) {
      return null
    }

    return [
      {
        payoutAmount: depositAmount,
        description: 'Deposit'
      },
      {
        payoutAmount: remainder,
        description: 'Remaining Balance'
      }
    ]
  }

  return {
    hasWisetack,
    merchantSignupDate,
    showOnOldDocuments,
    maximumLoanAmount,
    getPromo,
    handleTextLoanApplication,
    handleGetLoanApplication,
    handleSignup,
    getPayouts,
    createSignupLink
  }
}
