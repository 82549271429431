import { useMutation, useQuery } from '@apollo/react-hooks'
import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DropdownSelect } from '@ui/paintscout'
import { gql } from 'apollo-boost'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

export interface NewCouponCodeDialogProps extends DialogProps {
  title: string
  prefix?: string
  // returns search result of client
  onConfirm: (any) => any
  onClose: () => void
}

export default function NewCouponCodeDialog({ onConfirm, onClose, prefix, title, ...props }: NewCouponCodeDialogProps) {
  const [couponSet, setCouponSet] = useState(null)
  const [loadingMutation, setLoadingMutation] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const {
    loading,
    data,
    error: _error
    // updateQuery
  } = useQuery(
    gql`
      query couponSets($prefix: String) {
        couponSets(prefix: $prefix) {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: false,
      variables: {
        prefix
      }
    }
  )

  const [addCouponCode] = useMutation(gql`
    mutation addCouponCode($couponSet: String!) {
      addCouponCode(couponSet: $couponSet) {
        name
        status
        id
        code
      }
    }
  `)

  const options = (data?.couponSets ?? []).map((couponSet) => {
    return {
      label: couponSet.name,
      value: couponSet.id
    }
  })

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DropdownSelect
          loading={loading}
          label={'Coupon Set/Product'}
          options={options}
          variant={'single'}
          onChange={handleSelectChange}
          value={couponSet}
        />
      </DialogContent>
      <DialogActions
        leftButton={
          <Button variant="text" onClick={() => onClose()}>
            Cancel
          </Button>
        }
      >
        <Button onClick={handleConfirm} loading={loading || loadingMutation}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleConfirm() {
    setLoadingMutation(true)
    try {
      const res = await addCouponCode({ variables: { couponSet: couponSet.value } })
      onConfirm(res.data.addCouponCode)
    } catch (err) {
      setLoadingMutation(false)
      console.log(err)
      enqueueSnackbar(err?.message ?? 'Unable to add code')
    }
  }

  function handleSelectChange(newValue: DropdownSelectOption) {
    setCouponSet(newValue)
  }
}
