import type { OptionsDocument, QuoteDocument, QuoteOptions } from 'paintscout'
import type { QuoteItemSection, RenderableItemSubstrate } from '../../../..'
import { getAreaSubstrateDimension } from '../../../util/calculate/get-areaSubstrate-dimension'
import { formatCurrency } from '../../../../util/format-currency'

/**
 * @description Build label for renderableSubItem
 * @returns Label for item <string>
 */
export const getItemLabel = ({
  item,
  quoteOptions,
  options,
  section,
  totalPrice,
  quote
}: {
  item: RenderableItemSubstrate
  options: OptionsDocument
  quoteOptions: QuoteOptions
  section: QuoteItemSection
  quote: QuoteDocument
  totalPrice: number
}): string => {
  // Construct label elements to combine
  const labelElements = []

  if (item.rateType === 'quantity' && !item.hideQuantity) {
    labelElements.push(item.quantity)
  }

  const inlineAreaOptions = section === 'options' || section === 'pending' || section === 'additional'
  if (!quoteOptions.showDetailedBreakdown && !quoteOptions.stackSubstrates && item.showPrice && inlineAreaOptions) {
    labelElements.push(formatCurrency({ options, value: totalPrice }))
  }
  if (quoteOptions.stackSubstrates && item.showPrice) {
    labelElements.push(formatCurrency({ options, value: totalPrice }))
  }

  if (item.showCoats && item.useCoats) {
    labelElements.push(`${item.coats} Coat${item.coats === 1 ? '' : 's'}`)
  }

  if (item.showPrep && item.prep) {
    labelElements.push(`${item.prep} hr${item.prep === 1 ? '' : 's'} prep`)
  }

  if (item.showDimensions) {
    // Handle dimensions on areaSubstrate
    const length = item?.length?.useCustom ? item?.length?.custom ?? 0 : item?.length?.default ?? 0
    const width = item?.width?.useCustom ? item?.width?.custom ?? 0 : item?.width?.default ?? 0
    const height = item?.height?.useCustom ? item?.height?.custom ?? 0 : item?.height?.default ?? 0
    const rateType = item.rateType
    const isLnft = rateType === 'lnft'

    const itemArea = Object.values(quote.areas).find((area) => area.key === item.areaKey)

    const dimensionValue = getAreaSubstrateDimension({
      length,
      width,
      height,
      isRoom: itemArea?.area_type?.value === 'room',
      rateType
    })

    const dimension = `${dimensionValue} ${isLnft ? 'lnft' : 'sqft'}`
    labelElements.push(dimension)
  }

  // Include product details if present
  if (item.showProduct && item.products.length) {
    const { products } = item
    for (const product of products) {
      const productString = [product.label, product.color].filter((item) => item).join(' - ')
      if (productString) {
        labelElements.push(productString)
      }
    }
  }

  // Combine elements into full description
  const labelString = labelElements.length ? ` (${labelElements.join(', ')})` : ''

  return labelString
}
