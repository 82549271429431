import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import Grid from '../Grid'
import type { StyleClasses } from '../styles'
import type { FilterChipProps } from './NewFilterChip'
import FilterChip from './NewFilterChip'

const useStyles = makeStyles<Theme, FilterChipGroupProps>((theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: `repeat(auto-fill, minmax(140px, 1fr))`,
    // margin: theme.spacing(1),
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))'
    }
  }
}))

export interface FilterChipGroupProps {
  classes?: StyleClasses<typeof useStyles>
  chips: FilterChipProps[]
  loading?: boolean
}

export default function FilterChipGroup(props: FilterChipGroupProps) {
  const { chips, loading } = props

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      {chips.map((chip, index) => (
        <Grid item key={index}>
          <FilterChip loading={loading} {...chip} />
        </Grid>
      ))}
    </div>
  )
}
