import React from 'react'
import { FormSectionTitle, Grid } from '../..'
import { Divider, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  divider: {
    margin: theme.spacing(3, 0),
    height: 3
  }
}))

export const ProviderFormSection = ({
  title,
  subTitle,
  required,
  rightContent
}: {
  title: string
  subTitle?: string | React.ReactNode
  required?: boolean
  rightContent: JSX.Element
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <FormSectionTitle required={required} title={title} subTitle={subTitle} variant={'h4'} />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12} md={7}>
          {rightContent}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  )
}
